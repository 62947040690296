import React from "react";
import BreadcrumbWork from "../components/Work/BreadCrumbWork";
import Contact from "../components/home/Contact";
import Portfolio from "../components/Work/Portfolio";
import Detailpage from "../components/Work/Detailpage";
import Portfoliodetailpage from "../components/Work/Portfoliodetailpage";
import { Helmet } from 'react-helmet';


const Work = () => {
    return (
        <>
            <Helmet>
                <title>Es Magico: Our Portfolio of Successful Projects</title>
                <meta
                    name="description"
                    content="Explore our diverse portfolio of AI and digital projects, showcasing our expertise in delivering innovative solutions across various industries."
                />
                <meta
                    property="og:title"
                    content="Es Magico: Our Portfolio of Successful Projects"
                />
                <meta
                    property="og:description"
                    content="Explore our diverse portfolio of AI and digital projects, showcasing our expertise in delivering innovative solutions across various industries."
                />
            </Helmet>
            <div >
                <BreadcrumbWork />
                <Portfolio />
                <Contact />
                {/* <Detailpage />
            <Portfoliodetailpage /> */}
            </div>
        </>

    );
};

export default Work;
