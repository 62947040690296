import { useState, useEffect } from 'react';



import MobilePowerSteps from './MobilePower';
import PowerInside from './PowerInside';

export default function ResponsivePowerSteps() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? <MobilePowerSteps /> : <PowerInside />;
}