import React from "react";
import Rectangle106 from '../../assets/img/Rectangle106.png';
import Image1 from "../../assets/img/ISB Work.png";
import Image2 from "../../assets/img/RBIWork.png";
import Image3 from "../../assets/img/TataneuWork.png";
import Image4 from "../../assets/img/Krushaiwork3.png";
import Image5 from "../../assets/img/AdvancedWork.png";
import { ReactComponent as SlashIcon } from '../../assets/img/slash.svg'; // Adjusted path


const Detailpage = () => {
    return (
        <div className=" md:w-full mx-auto py-10 bg-white dark:bg-dark">
            <div className="md:w-full mx-auto container ">
                <div className=" md:w-full  mx-auto justify-items-center mb-8px  ">
                    <div className="md:W-full relative bg-center bg-center "
                    >
                        <div className="md:w-full mx-auto  py-10 shadow-1 dark:shadow-card bg-tg-bg dark:bg-dark-2 sm:px-6 md:px-[0px] md:py-6">
                            <div className="bg-[#F7F7F7]">
                                <ul className="flex items-center text-white py-4 bg-#F9F9F9  hidden md:flex">
                                    <li className="flex items-center md:ml-[80px]">
                                        <a
                                            href="javascript:void(0)"
                                            className="flex items-center text-gray-500  text-base text-black font-medium hover:text-primary dark:hover:text-primary text-dark dark:text-white"
                                        >
                                            Home
                                        </a>
                                        <span className=" px-2 text-body-color dark:text-dark-6">
                                            <SlashIcon width="7" height="12" className="fill-current" />
                                        </span>
                                    </li>
                                    <li className="flex items-center">
                                        <a
                                            href="javascript:void(0)"
                                            className="text-base text-gray-500  font-medium text-black dark:text-dark-6 dark:hover:text-primary hover:text-primary"
                                        >
                                            Work
                                        </a>
                                        <span className="px-2 text-body-color dark:text-dark-6">
                                            <SlashIcon width="7" height="12" className="fill-current" />
                                        </span>
                                    </li>
                                    <li className="text-base text-black bold font-medium text-primary">
                                        Products Built using AI
                                    </li>
                                </ul>
                                <div className=" bg-[#F7F7F7] h-[200px] flex justify-center items-center w-full ">
                                    <p className="text-black text-center font-outfit text-[28px] px-[50px] md:text-[70px] leading-[35px] md:leading-[80px]">
                                        <p className="text-[25px] leading-[25px] text-center font-semibold md:text-[38px] md:leading-[48px]">
                                            Products Built using&nbsp;
                                            <span className="bg-gradient-to-r from-[#FEA26E] to-[#538CFF] text-transparent bg-clip-text">
                                                AI
                                            </span>
                                        </p>
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className=" w-full   overflow-hidden">
                                    <div className="flex px-4 flex-wrap-reverse md:flex-nowrap mt-[30px] md:mt-[0px] lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8 md:mt-[90px]">
                                        <div className="flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mb-[147px] gap-[8px]">
                                            <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-left">
                                                Automated Applicant Review for ISB
                                            </p>
                                            <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                                We feed the candidate profile into our fine-tuned GPT model, which conducts a personalized interview. The candidate is then scored based on a set of predefined personality traits.
                                            </p>
                                        </div>
                                        <div className="relative justify-center rounded-lg overflow-hidden  md:mb-[120px] lg:mb-[100px]  2xl:mb-[50px] 2xl:ml-[150px] xl:ml-[108px] xl:mt-[40px] pb-6 max-w-[540px] max-h-[303.75px]">
                                            <img src={Image1} alt="Image1" className="object-cover" />
                                        </div>
                                    </div>
                                    <div className=" 2xl:mt-[40px] flex flex-wrap-reverse md:flex-nowrap lg:pl-[108px] bg-[#F9F9F9] lg:pr-[108px] items-center justify-center gap-8">
                                        <div className="flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mt-[0px] md:mb-[80px] xl:mb-[120px] 2xl:mb-[150px] gap-[8px]">
                                            <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-left">
                                                Automated Customer Support for RBI
                                            </p>
                                            <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                            Our application gathers basic user information and maps it to their respective bank details. It then extracts information about any excess charges and addresses any related queries they may have.
                                            </p>
                                        </div>
                                        <div className=" px-4 xl:px-[0px] mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden  xl:ml-[108px] pb-6 md:mb-[120px] xl:mb-[60px] lg:mb-[72px] max-w-[540px] max-h-[303.75px]">
                                            <img src={Image2} alt="Image2" className="object-cover" />
                                        </div>
                                    </div>

                                    <div className=" 2xl:mt-[70px] flex md:mt-[40px]  px-4 flex-wrap-reverse md:flex-nowrap lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8">
                                        <div className=" 2xl:mb-[100px] flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mb-[147px] lg:mb-[37px] gap-[8px]">
                                            <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-left">
                                                AI-Driven Rewards Platform to Boost User Engagement for TATA Neu
                                            </p>
                                            <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                                Our rewards platform harnesses Gen AI to dynamically generate content, notifications, and personalized offers based on user behavior and predefined rules, significantly enhancing engagement and satisfaction.
                                            </p>
                                        </div>
                                        <div className=" mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden md:mb-[150px]  lg:mb-[0px] xl:ml-[108px] pb-6 max-w-[540px] max-h-[303.75px]">
                                            <img src={Image3} alt="Image3" className="object-cover" />
                                        </div>
                                    </div>

                                    <div className="w-full overflow-hidden  md:mt-[30px]">
                                        <div className=" 2xl:mt-[70px] lg:mt-[50px] flex bg-[#F9F9F9]  flex-wrap-reverse md:flex-nowrap lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8">
                                            <div className="flex  flex-col justify-center ml-[26px] mr-[26px] mt-[40px] md:mt-[0px] lg:mb-[90px] md:mb-[80px] xl:mb-[107px] gap-[8px]">
                                                <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-left">
                                                    Multilingual AI Assistant for Indian Farmers
                                                </p>
                                                <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                                    KrushAI is an LLM model trained on agricultural data, specifically focusing on Indian crop cycles. Multilingual and currently operating in Hindi and Odiya, this product allows farmers to obtain answers to their queries in a straightforward, conversational manner.
                                                </p>
                                            </div>
                                            <div className=" px-4 mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden md:mb-[65.13px] md:mb-[190px] xl:mb-[100px] 2xl:mb-[50px] xl:ml-[108px] pb-6 max-w-[540px] max-h-[303.75px]">
                                                <img src={Image4} alt="Image4" className="object-cover" />
                                            </div>
                                        </div>

                                        <div className=" 2xl:mt-[70px] flex flex-wrap-reverse md:flex-nowrap lg:ml-[108px] lg:mr-[108px] items-center justify-center gap-8 mt-[30px]">
                                            <div className=" 2xl:mb-[180px] flex flex-col justify-center ml-[26px] mr-[26px] mt-[40px]  gap-[8px]">
                                                <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-left">
                                                    AI Driven Advanced Analystics for Sales Call Quality
                                                </p>
                                                <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                                Our Advanced Analytics leverages AI to perform sentiment analysis on sales meetings, assessing the quality of sales calls across different products and recommending potential corrective actions.
                                                </p>
                                            </div>
                                            <div className=" px-4 relative justify-center rounded-lg overflow-hidden  xl:ml-[150px] pb-6 max-w-[540px] max-h-[303.75px]">
                                                <img src={Image5} alt="Image5" className="object-cover" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div />
                </div >
            </div>
        </div >
    );
};

export default Detailpage;


