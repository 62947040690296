// import React from "react";
// import NewGratify from "../../assets/project/NewGratify.png";
// import NewEsto from "../../assets/project/NewEsto.png";
// import Sahay from "../../assets/project/Sahay.png";
// import AIInterviewer from "../../assets/project/AIInterviewer.png";
// import Slider from "react-slick";
// import { useNavigate } from 'react-router-dom';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const cards2 = [
//   {
//     imageUrl: NewGratify,
//     heading: "Gratify",
//     subheading: "Rewards & Gamification SaaS",
//     route: "/products/gratify",
//   },
//   {
//     imageUrl: NewEsto,
//     heading: "Esto",
//     subheading: "Smart Marketing & Sales SaaS",
//     route: "/products/esto",
//   },
//   {
//     imageUrl: Sahay,
//     heading: "Sahay",
//     subheading: "Intelligent AI-chatbot powered by RAG for Your Banking Needs",
//     // route: "/products/pyzo",
//   },
//   {
//     imageUrl: AIInterviewer,
//     heading: "AI Interviewer",
//     subheading:
//       "Streamline your hiring process with our AI-powered interviewing solution.",
//     // route: "/products/pyzo",
//   },
// ];

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3.5,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   responsive: [
//     {
//       breakpoint: 1536, // xl breakpoint
//       settings: {
//         slidesToShow: 3.5,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 1280, // lg breakpoint
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 1024, // md breakpoint
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 768, // sm breakpoint
//       settings: {
//         slidesToShow: 1.5,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 640, // xs breakpoint
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

// const SoftwareAsService = () => {
//   const navigate = useNavigate();

//   if (cards2.length === 0) {
//     return <p>No items available to display.</p>;
//   }

//   return (
//     <div className="container mx-auto px-4">
//       <div className="text-left gap-5 m-auto">
//         <Slider {...settings}>
//           {cards2.map((card, index) => (
//             <div
//               key={index}
//               className="rounded-lg overflow-hidden px-8"
//               style={{ cursor: "pointer" }}
//               onClick={() => card.route && navigate(card.route)}
//             >
//               <img
//                 className="w-full rounded-lg mb-4 h-auto md:h-70  object-cover"
//                 src={card.imageUrl}
//                 alt={card.heading}
//               />
//               <h3
//                 className="text-xl font-bold mb-2 overflow-hidden text-[#0F0F0F]"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.heading}
//               </h3>
//               <p
//                 className="text-[#0F0F0F] mb-8 text-lg overflow-hidden"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.subheading}
//               </p>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default SoftwareAsService;


// import React from "react";
// import NewGratify from "../../assets/project/NewGratify.png";
// import NewEsto from "../../assets/project/NewEsto.png";
// import Sahay from "../../assets/project/Sahay.png";
// import AIInterviewer from "../../assets/project/AIInterviewer.png";
// import Slider from "react-slick";
// import { useNavigate } from 'react-router-dom';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const cards2 = [
//   {
//     imageUrl: NewGratify,
//     heading: "Gratify",
//     subheading: "Rewards & Gamification SaaS",
//     route: "/products/gratify",
//   },
//   {
//     imageUrl: NewEsto,
//     heading: "Esto",
//     subheading: "Smart Marketing & Sales SaaS",
//     route: "/products/esto",
//   },
//   {
//     imageUrl: Sahay,
//     heading: "Sahay",
//     subheading: "Intelligent AI-chatbot powered by RAG for Your Banking Needs",
//     // route: "/products/pyzo",
//   },
//   {
//     imageUrl: AIInterviewer,
//     heading: "AI Interviewer",
//     subheading:
//       "Streamline your hiring process with our AI-powered interviewing solution.",
//     // route: "/products/pyzo",
//   },
// ];

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 3,
//   initialSlide: 0,
//   responsive: [
//     {
//       breakpoint: 770,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1.07,
//         slidesToScroll: 1,
//         initialSlide: 1.5,
//       },
//     },
//   ],
// };

// const SoftwareAsService = () => {
//   const navigate = useNavigate()
//   return (
//     <div>
//       <div className="hidden lg:grid gap-[100px] grid-cols-2 ">
//         {cards2.map((card, index) => (
//           <div
//             key={index}
//             className="rounded-lg overflow-hidden hover:scale-105 aspect-w-1 aspect-h-1"
//             style={{ cursor: "pointer" }}
//             onClick={() => navigate(card.route)}
//           >
//             <img
//               className="w-full h-auto rounded-lg mb-4"
//               src={card.imageUrl}
//               alt="Image"
//             />
//             <h3
//               className="text-xl font-bold mb-2 overflow-hidden text-[#0F0F0F]"
//               style={{
//                 WebkitLineClamp: 2,
//                 WebkitBoxOrient: "vertical",
//                 display: "-webkit-box",
//               }}
//             >
//               {card.heading}
//             </h3>
//             <p
//               className="text-[#0F0F0F] mb-8 text-lg overflow-hidden"
//               style={{
//                 WebkitLineClamp: 2,
//                 WebkitBoxOrient: "vertical",
//                 display: "-webkit-box",
//               }}
//             >
//               {card.subheading}
//             </p>
//           </div>
//         ))}
//       </div>

//       <div className=" text-left gap-5 m-auto block lg:hidden">
//         <Slider {...settings}>
//           {cards2.map((card, index) => (
//             <div key={index} className="rounded-lg overflow-hidden w-full px-5" style={{ cursor: "pointer" }}
//               onClick={() => navigate(card.route)}>
//               <img
//                 className=" h-auto rounded-lg mb-4 mx-auto w-11/12"
//                 // style={{ width: "95%"}}
//                 src={card.imageUrl}
//                 alt="Image"
//               />
//               <h3
//                 className="text-xl font-bold mb-2 overflow-hidden ms-3 lg:ms-4"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.heading}
//               </h3>
//               <p
//                 className="text-gray-700 mb-8 text-lg overflow-hidden ms-3 lg:ms-4"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.subheading}
//               </p>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default SoftwareAsService;

// import React from "react";
// import NewGratify from "../../assets/project/NewGratify.png";
// import NewEsto from "../../assets/project/NewEsto.png";
// import Sahay from "../../assets/project/Sahay.png";
// import AIInterviewer from "../../assets/project/AIInterviewer.png";
// import Slider from "react-slick";
// import { useNavigate } from 'react-router-dom';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const cards2 = [
//   {
//     imageUrl: NewGratify,
//     heading: "Gratify",
//     subheading: "Rewards & Gamification SaaS",
//     route: "/products/gratify",
//   },
//   {
//     imageUrl: NewEsto,
//     heading: "Esto",
//     subheading: "Smart Marketing & Sales SaaS",
//     route: "/products/esto",
//   },
//   {
//     imageUrl: Sahay,
//     heading: "Sahay",
//     subheading: "Intelligent AI-chatbot powered by RAG for Your Banking Needs",
//     // route: "/products/pyzo",
//   },
//   {
//     imageUrl: AIInterviewer,
//     heading: "AI Interviewer",
//     subheading:
//       "Streamline your hiring process with our AI-powered interviewing solution.",
//     // route: "/products/pyzo",
//   },
// ];

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   responsive: [
//     {
//       breakpoint: 770,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1.07,
//         slidesToScroll: 1,
//         initialSlide: 1.5,
//       },
//     },
//   ],
// };

// const SoftwareAsService = () => {
//   const navigate = useNavigate();

//   return (
//     <div>
//       {/* 2x2 Grid Layout for Larger Screens */}
//       <div className="hidden  mx-[40px] lg:grid gap-[50px] grid-cols-2 justify-center items-center">
//         {cards2.map((card, index) => (
//           <div
//             key={index}
//             className="rounded-lg overflow-hidden hover:scale-105 cursor-pointer"
//             onClick={() => navigate(card.route)}
//           >
//             <img
//               className="w-full h-[250px] md:h-[300px] lg:h-[350px] max-w-xs object-cover rounded-lg mb-4"
//               src={card.imageUrl}
//               alt="Image"
//             />
//             <h3
//               className="text-xl font-bold mb-2 overflow-hidden text-[#0F0F0F]"
//               style={{
//                 WebkitLineClamp: 2,
//                 WebkitBoxOrient: "vertical",
//                 display: "-webkit-box",
//               }}
//             >
//               {card.heading}
//             </h3>
//             <p
//               className="text-[#0F0F0F] mb-8 text-lg overflow-hidden  md:mr-[180px]"
//               style={{
//                 WebkitLineClamp: 2,
//                 WebkitBoxOrient: "vertical",
//                 display: "-webkit-box",
//               }}
//             >
//               {card.subheading}
//             </p>
//           </div>
//         ))}
//       </div>

//       {/* Slider for Mobile View */}
//       <div className="block lg:hidden">
//         <Slider {...settings}>
//           {cards2.map((card, index) => (
//             <div
//               key={index}
//               className="rounded-lg overflow-hidden w-full px-5 cursor-pointer"
//               onClick={() => navigate(card.route)}
//             >
//               <img
//                 className="w-full h-[250px] md:h-[300px] lg:h-[350px] max-w-xs object-cover rounded-lg mb-4 mx-auto w-11/12"
//                 src={card.imageUrl}
//                 alt="Image"
//               />
//               <h3
//                 className="text-xl font-bold mb-2 overflow-hidden ms-3 lg:ms-4"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.heading}
//               </h3>
//               <p
//                 className="text-gray-700 mb-8 text-lg overflow-hidden ms-3 lg:ms-4"
//                 style={{
//                   WebkitLineClamp: 2,
//                   WebkitBoxOrient: "vertical",
//                   display: "-webkit-box",
//                 }}
//               >
//                 {card.subheading}
//               </p>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default SoftwareAsService;

import React from "react";
import NewGratify from "../../assets/project/NewGratify.png";
import NewEsto from "../../assets/project/NewEsto.png";
import Sahay from "../../assets/project/Sahay.png";
import AIInterviewer from "../../assets/project/AIInterviewer.png";
import { useNavigate } from 'react-router-dom';

const cards2 = [
  {
    imageUrl: NewGratify,
    heading: "Gratify",
    subheading: "Rewards & Gamification SaaS",
    route: "/products/gratify",
  },
  {
    imageUrl: NewEsto,
    heading: "Esto",
    subheading: "Smart Marketing & Sales SaaS",
    route: "/products/esto",
  },
  {
    imageUrl: Sahay,
    heading: "Sahay",
    subheading: "Intelligent AI-Assistant powered by RAG for Your Banking Needs",
  },
  {
    imageUrl: AIInterviewer,
    heading: "AI Interviewer",
    subheading: "Streamline your hiring process with our AI-powered interviewing solution.",
  },
];

const SoftwareAsService = () => {
  const navigate = useNavigate();

  return (
    <div className="px-3 py-8">
      {/* Grid Layout for Larger Screens */}
      <div className="hidden lg:grid grid-cols-2 gap-10 mx-[50px]">
        {cards2.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center rounded-lg overflow-hidden hover:scale-105 cursor-pointer"
            onClick={() => navigate(card.route)}
          >
            <img
              className="w-full h-auto max-w-[60%] object-contain rounded-lg mb-4"
              src={card.imageUrl}
              alt={card.heading}
            />
            <h3
              className="text-xl font-bold mb-2 text-[#0F0F0F] text-center"
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.heading}
            </h3>
            <p
              className="text-[#0F0F0F] mb-8 mx-[55px] text-lg text-center"
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.subheading}
            </p>
          </div>
        ))}
      </div>

      {/* Grid Layout for Mobile and Medium Screens */}
      <div className="block lg:hidden grid grid-cols-1 md:grid-cols-2 gap-9 mx-auto">
        {cards2.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center rounded-lg overflow-hidden w-full cursor-pointer"
            onClick={() => navigate(card.route)}
          >
            <img
              className="px-4 w-full h-auto max-w-[100%] object-contain rounded-lg mb-4"
              src={card.imageUrl}
              alt={card.heading}
            />
            <h3
              className="text-xl font-bold mb-2 text-[#0F0F0F] text-center"
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.heading}
            </h3>
            <p
              className="text-[#0F0F0F] mb-4 text-lg text-center "
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.subheading}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoftwareAsService;
