// import React from "react";
// import mapImg from "../../assets/img/mapImg.jpg";

// const Globe1 = () => {
//   return (
//     <div className="md:relative mt-[40px] lg:mt-[0px] ">
//       <div className="md:absolute w-[55%] md:w-[100%] text-center  m-auto lg:m-0 lg:top-[64px] lg:left-1/2 lg:transform lg:-translate-x-1/2 text-[20px] lg:text-[36px] font-semibold">
//         We have launched 15+ products in 7+ countries
//       </div>
//       <img src={mapImg} alt="img" />
//     </div>
//   );
// };

// export default Globe1;

// import React from "react";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import mapImg from "../../assets/img/mapImg.jpg";

// const Globe1 = () => {
//   const isMobile = window.innerWidth <= 768; // Define your breakpoint for mobile

//   return (
//     <div className="relative mt-10 lg:mt-0">

//       <div>
//         {isMobile ? (
//           <TransformWrapper
//             defaultScale={1}
//             defaultPositionX={1}
//             defaultPositionY={1}
//             options={{ limitToBounds: true }}
//             doubleClick={{ disabled: true }}  // Disable double-click zoom
//             pinch={{ step: 5 }}
//           >
//             <TransformComponent>
//               <img src={mapImg} alt="img" className="w-full object-cover mt-[70px]" />
//             </TransformComponent>
//           </TransformWrapper>
//         ) : (
//           <img src={mapImg} alt="img" className="w-full object-cover" />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Globe1;

import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import mapImg from "../../assets/img/mapImg.jpg";

const Globe1 = () => {
  // State to determine if the device is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="relative mt-[10px] lg:mt-0 w-full  overflow-hidden">
      <div className="w-full  flex justify-center items-center">
        {isMobile ? (
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            options={{ limitToBounds: true }}
            doubleClick={{ disabled: true }} // Disable double-click zoom
            pinch={{ step: 5 }} // Control pinch sensitivity
          >
            <TransformComponent>
              <img src={mapImg} alt="img" className="w-full  " />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <img src={mapImg} alt="img" className="w-full " />
        )}
      </div>
    </div>
  );
};

export default Globe1;
