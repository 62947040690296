import logo from "./logo.svg";
import "./App.css";
import About from "./components/home/About";
import Contact from "./components/home/Contact";
import Footer from "./components/home/Footer";
import Projects from "./components/home/Projects";
import Testimonial from "./components/home/Testimonial";
import { Navbar } from "./components/Navbar";
import Hero from "./components/home/Hero";
import Expertise from "./components/home/Expertise";
import InsightsAndExpertise from "./components/home/InsightsAndExpertise";
import Globe from "./components/home/Globe";
import { useEffect } from "react";
import AllRoutes from "./components/AllRoutes";
import BackToTop from "./components/gratify/BackToTop";

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="App">
      <Navbar />
      <AllRoutes />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
