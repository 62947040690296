import React, { useState, useEffect } from 'react';
import { IoIosArrowRoundUp } from 'react-icons/io';

const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            className={`fixed bottom-7 right-8 w-[63px] h-[63px]  rounded-full ml-[800px] z-40 transition-all duration-1000 ease-in-out ${visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-24'}`}
            style={{ boxShadow: '0px 2px 8px 0px #0000001F' }}
        >
            <div
                className="w-full h-full bg-[#FEA26E] flex justify-center items-center rounded-full cursor-pointer"
                onClick={scrollToTop}
            >
                <IoIosArrowRoundUp size={32} color="#FFFFFF" />
            </div>
        </div>
    );
};

export default BackToTop;
