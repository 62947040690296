import React from "react";
import efficiency1 from "../../assets/svg/efficiency1.svg";
import efficiency2 from "../../assets/svg/efficiency2.svg";
import efficiency3 from "../../assets/svg/efficiency3.svg";
import efficiency4 from "../../assets/svg/efficiency4.svg";
import efficiency5 from "../../assets/svg/efficiency5.svg";
import efficiency6 from "../../assets/svg/efficiency6.svg";
import efficiency7 from "../../assets/svg/efficiency7.svg";
import efficiency8 from "../../assets/svg/efficiency8.svg";
import efficiency9 from "../../assets/svg/efficiency9.svg";
import efficiency10 from "../../assets/svg/efficiency10.svg";

const list = [
  {
    text: "Scalable",
    icon: efficiency1,
  },
  {
    text: "Microservices",
    icon: efficiency2,
  },
  {
    text: "Agile",
    icon: efficiency3,
  },
  {
    text: "Open Source",
    icon: efficiency4,
  },
  {
    text: "Easy UX",
    icon: efficiency5,
  },
  {
    text: "API First",
    icon: efficiency6,
  },
  {
    text: "Cloud Native",
    icon: efficiency7,
  },
  {
    text: "AI Powered",
    icon: efficiency8,
  },
  {
    text: "Hybrid Technologies",
    icon: efficiency9,
  },
  {
    text: "Clean UI",
    icon: efficiency10,
  },
];

const Efficiency = () => {
  return (
    <div className="mb-[120px] w-[100%] lg:w-[90%] m-auto mt-[20px] md:mt-[0px] ">
      <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center ">
        We optimize every step:
      </h1>
      <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center px-1">
        Building products with maximum efficiency.
      </h1>
      <div className="flex flex-wrap px-4 md:px-0 m-auto gap-[18px] lg:gap-[68px] justify-center lg:justify-center  mt-[50px] ">
        {list?.map((item) => {
          return (
            <div className="flex text-[16px] lg:text-[22px] font-medium px-[16px] lg:px-[34.5px] gap-2 shadow bg-white py-[16px] lg:py-[23px] min-w-[100px] lg:min-w-[200px] rounded-lg items-center justify-center">
              <img src={item?.icon} alt="" /> {item?.text}{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Efficiency;
