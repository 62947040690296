import React from 'react'

export const MemberCard = ({name, img, post}) => {
  return (
    <div className='py-[24px] bg-[#FFFFFF]  rounded-[20px] flex flex-col items-center w-[158px] lg:w-[284px] px-[23px] lg:py-[55px] '>
<img src= {img} className=' h-[65px] w-[65px] rounded-full  lg:h-[100px] lg:w-[100px] mb-[20px] lg:mb-[48px] '  />
<p className='text-[14px] lg:text-[20px] lg:leading-[38px] text-center'> 
{name}
</p>
<p className='text-[#538CFF] text-[12px] text-center lg:text-[18px]'>
   {post} 
</p>
    </div>
  )
}
