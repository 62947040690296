import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Services from '../pages/Services'
import Products from '../pages/Products'
import Gratify from '../pages/Gratify'
import Esto from '../pages/Esto'
import Work from '../pages/Work'
import Teams from '../pages/Teams'

import Detailpage from './Work/Detailpage'
import Portfoliodetailpage from './Work/Portfoliodetailpage'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/services' element={<Services />}></Route>
      <Route path='/products' element={<Products />}></Route>
      <Route path='/products/gratify' element={<Gratify />}></Route>
      <Route path='/products/Esto' element={<Esto />}></Route>
      <Route path='/Work' element={<Work />}></Route>
      <Route path='/Teams' element={<Teams />}></Route>
      <Route path='/Work/Detailpage' element={<Detailpage />}></Route>
      <Route path='/Work/Portfoliodetailpage' element={<Portfoliodetailpage />}></Route>
    </Routes>
  )
}

export default AllRoutes