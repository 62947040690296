import React, { useEffect, useRef } from 'react';
import Stacking1 from '../../assets/img/Stacking1.png'
import Offers1 from "../../assets/img/Offers1.png";
import Stacking4 from "../../assets/img/Stacking4.png";
import Gratify from "../../assets/img/Gratify.png";

const Card = ({ title, description1, description2, imageUrl, index }) => {
    return (
        <div className="sticky  flex items-center justify-center mx-[25px] " style={{ top: `${(index + 1) * 10}px`, opacity: 1 }}>
            <div className="p-5 shadow-[0_0_30px_0_rgba(0,0,0,0.3)] rounded-[24px]  transition-all duration-300 text-white origin-top" >
                <div className=" flex flex-col md:flex-row sticky items-center md:gap-[46px] w-[100%] " style={{ top: `${(index + 1) * 10}px` }}>
                    <div className="  w-[90%]  md:ml-[40px] px-2 md:w-[60%] shadow-[0_0_30px_0_rgba(0,0,0,0.3)] text-center md:text-start transition-all duration-300 text-white origin-top" >
                        <h1 className="font-['Outfit'] text-xl md:text-2xl lg:text-4xl font-semibold bg-transparent leading-6 md:leading-9 mb-4 " >{title}</h1>
                        <p className="font-['Outfit'] text-lg md:text-xl lg:text-2xl 2xl:text-4xl font-normal leading-6 md:leading-8 bg-transparent ">{description1}</p>
                        <p className="font-['Outfit'] text-lg md:text-xl lg:text-2xl 2xl:text-4xl font-normal leading-6 md:leading-8 bg-transparent">{description2}</p>
                    </div>
                    <div className="relative flex md:flex-row mt-[10px] md:mt-[0px] " style={{ width: '60%' }}>
                        <img src={imageUrl} alt={title} className="object-cover  2xl:h-[30vh] " />
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardsContainer = ({ cards }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const cards = containerRef.current.querySelectorAll('.sticky');
            const data = {
                Location: {},
                ToReduceBy: {},
            };

            cards.forEach((card, index) => {
                const stickyTop = parseInt(window.getComputedStyle(card).top);
                const currentTop = card.getBoundingClientRect().top;
                data.Location[index] = currentTop - stickyTop;
                data.ToReduceBy[index] = 0;
            });

            for (let i = 0; i < cards.length; i++) {
                if (data.Location[i] <= 20) {
                    for (let j = i; j > -1; j--) {
                        data.ToReduceBy[j]++;
                    }
                }
            }

            cards.forEach((card, index) => {
                const innerCard = card.firstElementChild;
                const backgroundColorAlpha = 1 - (data.ToReduceBy[index] - 1) * 0.05;
                innerCard.style.transform = `scale(${1 - (data.ToReduceBy[index] * 0.05)})`;
                innerCard.style.backgroundColor = `rgba(0,0,0,${backgroundColorAlpha})`;
            }
            );
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <div className="flex flex-col items-center justify-center " ref={containerRef}>
            {cards.map((card, index) => (
                <Card key={index} {...card} index={index} />
            ))}
        </div>
    );
};

const card = () => {

    const cardsData = [
        {
            title: "Role Management & Security",
            description1: "Stay in control",
            description2: "Assign roles and permissions to keep your gamification strategy secure.",
            imageUrl: Stacking4,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Offer Activation & Monitoring",
            description1: "Launch & Track",
            description2: "Easily activate your campaigns and see how they perform in real-time.",
            imageUrl: Offers1,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Offer Creation & Management",
            description1: "Craft Epic Rewards",
            description2: "Design personalized offers that users will love! See it Live! with interactive previews before launching.",
            imageUrl: Gratify,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Dashboard & User Data ",
            description1: "Know Your Users",
            description2: "Understand how they interact with your products and rewards!",
            imageUrl: Stacking1,
            backgroundColor: "rgba(0,0,0)",
        }
    ];

    return (
        <div className="w-fullh-screen flex items-center justify-center  mx-auto  ">
            <CardsContainer cards={cardsData} />
        </div>
    );
};

export default card;