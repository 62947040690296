import React from "react";
import rbiimage from "../../assets/img/rbiimage.png";
import tataneu1 from "../../assets/img/tataneu1.png";
import samagra2 from "../../assets/img/samagra2.png";
import isb from "../../assets/img/isb.png";
import dominos from "../../assets/img/dominos.png";
import plum2 from "../../assets/img/plum2.png";
import bookmyshow1 from "../../assets/img/bookmyshow1.png";
import peekup2 from "../../assets/img/peekup2.jpg";
import HDFCCapital from "../../assets/img/HDFCCapital.png";
import keka from "../../assets/img/keka.png";
import YPay from "../../assets/img/YPay.png";


const Projects = () => {
  return (

    // <div className=" mx-auto md:relative  lg:mb-[0] md:mb-[90px] md:flex justify-center items-center py-8 xl:pt-[100px] ">
    //   <div className="md:w-[82vw]  md:ml-[85px]  md:mr-[85px] flex justify-center items-center">
    //     <div className="grid grid-cols-3 md:px-4 px-8  md:mb-[90px]  gap-12 md:gap-[35px] md:flex md:flex-wrap justify-around md:justify-center  justify-center md:items-center ">
    //       <img src={rbiimage} alt="" className=" pl-[10px] md:pl-[0px] w-[15vw] md:w-[8vw] lg:w-[4vw] justify-center object-contain" />
    //       <img src={tataneu1} alt="" className=" h-[5vh] md:w-[12vw] lg:w-[6vw]  object-contain md:mt-[1.07px] md:mb-[1.07px]" />
    //       <img src={samagra2} alt="" className=" h-[5vh] md:w-[20vw] lg:w-[12vw]  object-contain md:mt-[9.22px] md:mb-[4.55px]" />
    //       <img src={isb} alt="" className=" md:w-[10vw] lg:w-[6vw]  object-contain md:mt-[1.07px] md:mb-[1.07px]" />
    //       <img src={dominos} alt="" className=" md:w-[17vw] lg:w-[11vw]  object-contain md:mt-[7.89px] md:mb-[6.35px]" />
    //       <img src={plum2} alt="" className=" md:w-[11vw] lg:w-[6vw]  object-contain md:mt-[11.07px] md:mb-[6.35px]" />
    //       <img src={bookmyshow1} alt="" className="  md:w-[14vw] lg:w-[9vw]  object-contain md:mt-[11.07px] md:mb-[6.35px]" />
    //       <img src={peekup2} alt="" className=" md:w-[14vw] lg:w-[9vw]   object-contain " />
    //       <img src={HDFCCapital} alt="" className=" md:w-[12vw] lg:w-[7vw]   object-contain" />
    //       <img src={keka} alt="" className="md:w-[13vw] lg:w-[7.5vw]   object-contain" />
    //       <img src={YPay} alt="" className=" md:w-[11vw] lg:w-[6vw]  object-contain" />
    //     </div>
    //   </div>
    // </div>


    <div className=" mx-auto md:relative  lg:mb-[0] md:mb-[90px] md:flex justify-center items-center py-8  xl:pt-[100px] overflow-hidden ">
      <div className="md:w-[82vw]  md:ml-[85px]  md:mr-[85px] flex justify-center items-center">
        <div className="flex flex-wrap px-[3vw] md:px-4 lg:px-[0] gap-[10vw] md:gap-[8vw] lg:gap-[4vw] md:flex md:flex-wrap justify-evenly md:justify-center  md:items-center ">
          <img src={rbiimage} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[6vh] xl:w-[5vw] xl:h-[8vh] 2xl:w-[9vw] 2xl:h-[7vh] object-contain" />
          <img src={tataneu1} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[6vh] xl:w-[7vw] xl:h-[6vh] object-contain " />
          <img src={samagra2} alt="" className="w-[17vw] h-[5vh]  md:w-[17vw] md:h-[6vh] lg:w-[10vw] lg:h-[7vh] object-contain " />
          <img src={isb} alt="" className=" w-[17vw] h-[5vh]  md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={dominos} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={plum2} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={bookmyshow1} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={peekup2} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={HDFCCapital} alt="" className=" w-[17vw] h-[5vh]  md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain" />
          <img src={keka} alt="" className="w-[17vw] h-[5vh]  md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
          <img src={YPay} alt="" className=" w-[17vw] h-[5vh] md:w-[17vw] md:h-[6vh] lg:w-[8vw] lg:h-[7vh] object-contain " />
        </div>
      </div>
    </div>

  );
};
export default Projects;
