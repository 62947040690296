import React from 'react';
import settings from "../../assets/img/settings.png";
import tick from "../../assets/img/tick.png";
import arrow from "../../assets/img/arrow.png";
import arrows from "../../assets/img/arrows.png";

const Gratifyscales = () => {
    return (
        <div className="w-full mx-auto ">
            <div className="flex flex-col justify-center md:flex-row md:ml-[102px] md:mr-[102px] mb-[44px] md:mt-[55px]  mx-auto rounded-[39px] bg-[#F9F9F9] border-3 border-white">
                <div className="flex flex-col h-auto p-6 gap-[42px]">
                    <p className="text-[24px] md:text-[50px] md:text-start text-center font-outfit font-bold leading-[63px] text-[#0F0F0F]">
                        Gratify scales with you
                    </p>
                    <div className="flex flex-col gap-[37px]">
                        <div className="flex items-start md:mt-[42px] ">
                            <div className=" mr-[23.5px]">
                                <img
                                    src={settings}
                                    alt="Settings"
                                    className=" object-cover rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col ">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Quick Setup
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Get started fast with our intuitive interface. No coding needed!
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start  ">
                            <div className=" mr-[23.5px]">
                                <img
                                    src={tick}
                                    alt="tick"
                                    className=" object-cover rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col ">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Security First
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Your data is safe with our industry-standard security measures.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start ">
                            <div className="relative  ">
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className="w-full h-full object-cover rounded-lg  "
                                />
                            </div>
                            <div className="flex flex-col md:ml-[23px] md:mb-[10px] w-full">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Flexible & Scalable
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Gratify adapts to your platform size, no matter how big you grow.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex justify-center items-center md:mt-[131px] ml-[75px] md:mr-[62px] md:ml-[10px] mr-[75px]">
                    <img
                        src={arrows}
                        alt="arrows"
                        className="object-cover mix-blend-luminosity  "
                    />
                </div>
            </div>
        </div>
    );
};

export default Gratifyscales;
