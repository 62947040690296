import React from 'react';
import Blackbg from '../../assets/img/Blackbg.png';
import Presale from '../../assets/img/Presale.png';

const EstoSection1 = () => {
    return (
        <div className=" w-auto" style={{
            backgroundImage: `url(${Blackbg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            maxWidth: '100%'
        }}>
            <div className="mx-auto  mt-[500px] md:mt-[700px] flex flex-col items-center ml-[45px] mr-[45px] lg:ml-[230px] lg:mr-[230px]">
                <div className="flex flex-col items-center gap-[15px] mt-[57.78px]">
                    <p className="font-outfit text-[24px] text-center md:text-[45px] font-semibold leading-[30px] md:leading-[40px] lg:leading-[63px] text-[#FDFDFD]">
                        Esto
                    </p>
                    <p className="w-full h-[60px] font-outfit text-[14px] md:text-[24px] font-normal leading-[17px]  md:leading-[30.24px] text-center text-[#FDFDFD]">
                        Your All-in-One Sales & Marketing Command Center
                    </p>
                </div>
                <button className="text-white flex items-center justify-center mb-[30px] rounded-[63px] border border-white ">
                    <p className="text-white text-center text-[18px] font-bold py-2 px-6 ">
                        Start your journey today!
                    </p>
                </button>
            </div>

            <div className='flex   justify-center items-center'>
                < img
                    src={Presale}
                    alt="Presale"
                    className=" object-cover max-w-none w-1/2   object-contain  w-[80%] h-auto "
                />
            </div>
        </div >

    );
};

export default EstoSection1;
