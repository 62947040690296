// import React, { useEffect, useRef } from 'react';
// import esto2 from "../../assets/img/esto2.png";
// import pyzo2 from "../../assets/img/pyzo2.png";
// import gratify2 from "../../assets/img/gratify2.png";

// const StackingpageshomeCard = ({ title, description2, imageUrl, backgroundColor, index }) => {
//     return (
//         <div className="sticky  flex items-center justify-center  mt-[41.65px] md:mt-[150px] " style={{ top: `${(index + 1) * 10}px`, opacity: 1 }}>
//             <div className="p-5  shadow-[0_0_30px_0_rgba(0,0,0,0.3)] transition-all duration-300 text-white origin-top" style={{ backgroundColor, borderRadius: '30px' }}>
//                 <div className="flex flex-col  md:flex-row sticky items-center md:gap-[46px] w-full md:mb-[50px] md:mt-[50px]" style={{ top: `${(index + 1) * 10}px` }}>
//                     <div className="w-[60%]  text-center md:text-start transition-all duration-300 text-white origin-top">
//                         <h1 className="font-['Outfit']  md:text-xl lg:text-4xl 2xl:text-5xl font-semibold bg-transparent leading-[25.2px] md:leading-[63px] mb-4">{title}</h1>
//                         <p className="font-['Outfit'] md:text-2xl lg:text-3xl 2xl:text-4xl font-normal leading-[30.24px] bg-transparent">{description2}</p>
//                     </div>
//                     <div className="relative flex" style={{ width: '60%', height: 'auto' }}>
//                         <img src={imageUrl} alt={title} className="object-cover rounded-tl-[24px]" />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const CardsContainer = ({ cards }) => {
//     const containerRef = useRef(null);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (!containerRef.current) return;

//             const cards = containerRef.current.querySelectorAll('.sticky');
//             const data = {
//                 Location: {},
//                 ToReduceBy: {},
//             };

//             cards.forEach((card, index) => {
//                 const stickyTop = parseInt(window.getComputedStyle(card).top);
//                 const currentTop = card.getBoundingClientRect().top - 200;
//                 data.Location[index] = currentTop - stickyTop;
//                 data.ToReduceBy[index] = 0;
//             });

//             for (let i = 0; i < cards.length; i++) {
//                 if (data.Location[i] <= 20) {
//                     for (let j = i; j > -1; j--) {
//                         data.ToReduceBy[j]++;
//                     }
//                 }
//             }

//             cards.forEach((card, index) => {
//                 const innerCard = card.firstElementChild;
//                 const backgroundColorAlpha = 1 - (data.ToReduceBy[index] - 1) * 0.1;
//                 innerCard.style.transform = `scale(${1 - (data.ToReduceBy[index] * 0.10)})`;
//                 //innerCard.style.backgroundColor = `rgba(0, 0, 0, ${backgroundColorAlpha})`;
//             });
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, []);

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     return (
//         <div className="flex flex-col items-center justify-center" ref={containerRef}>
//             {cards.map((card, index) => (
//                 <StackingpageshomeCard key={index} {...card} index={index} />
//             ))}
//         </div>
//     );
// };

// const Stackingpageshome = () => {
//     const cardsData = [
//         {
//             title: "Pyzo",
//             description2: "Revolutionize customer interactions with our conversational AI platform, delivering seamless communication and support.",
//             imageUrl: pyzo2,
//             backgroundColor: "rgba(0,0,0)",
//         },
//         {
//             title: "Esto",
//             description2: "Supercharge your marketing and sales efforts with our smart SaaS solution, driving results through data-driven insights.",
//             imageUrl: esto2,
//             backgroundColor: "rgba(0,0,0)",
//         },
//         {
//             title: "Gratify",
//             description2: "Elevate customer engagement with our rewards and gamification platform that turns loyalty into an experience.",
//             imageUrl: gratify2,
//             backgroundColor: "rgba(0,0,0)",
//         },
//     ];

//     return (
//         <div className="w-full  mx-auto md:mt-[60px]  md:mb-[80px]">
//             <div className=' grid justify-center gap-[6.35px]  '>
//                 <p
//                     className=" text-[20px] md:text-[38px] md:mt-[65px] font-semibold leading-[25.2px] md:leading-[47.88px] text-center pl-[10px] pr-[10px]"
//                     style={{ color: 'var(--Color-2, #0F0F0F)', opacity: '1' }}
//                 >
//                     Add our Secret Ingredients to Your Suite
//                 </p>
//                 <p
//                     className="text-[16px] md:text-[24px] font-normal  leading-[22.4px] md:leading-[33.6px] text-center md:pl-[20px] md:pr-[20px] "
//                     style={{ color: 'var(--Color-2, #0F0F0F)', opacity: '0.6' }}
//                 >
//                     Integrate our unique SaaS solutions to boost your product capabilities and deliver exceptional value to your customers.
//                 </p>
//             </div>
//             <CardsContainer cards={cardsData} />
//         </div>
//     );
// };

// export default Stackingpageshome;

import React, { useEffect, useRef, useState } from 'react';
import esto2 from "../../assets/img/esto2.png";
import pyzo2 from "../../assets/img/pyzo2.png";
import gratify2 from "../../assets/img/gratify2.png";
import Sahay from "../../assets/project/Sahay.png";
import AIInterviewer from "../../assets/project/AIInterviewer.png";

const StackingpageshomeCard = ({ title, description2, imageUrl, backgroundColor, index }) => {
    const [cardHeight, setCardHeight] = useState('300px'); // Default height

    useEffect(() => {
        const updateHeight = () => {
            if (window.innerWidth >= 1536) {
                setCardHeight('580px'); // 2xl screens
            } else if (window.innerWidth >= 1024) {
                setCardHeight('500px'); // Large screens
            } else if (window.innerWidth >= 768) {
                setCardHeight('350px'); // Medium screens
            } else {
                setCardHeight('440px'); // Small screens
            }
        };

        updateHeight(); // Set initial height
        window.addEventListener('resize', updateHeight); // Update height on resize

        return () => window.removeEventListener('resize', updateHeight); // Clean up listener
    }, []);

    return (
        <div
            className="sticky flex items-center justify-center mt-10 md:mt-20 lg:mt-20  "
            style={{ top: `${(index + 1) * 10}px`, opacity: 1 }}
        >
            <div
                className="p-5 shadow-[0_0_30px_0_rgba(0,0,0,0.3)] transition-all duration-300  text-white origin-top"
                style={{
                    backgroundColor,
                    borderRadius: '30px',
                    height: cardHeight, // Apply dynamic height
                    width: '95%'
                }}
            >
                <div
                    className="flex  flex-col justify-around   md:flex-row items-center md:gap-12 w-full md:mb-12 "
                    style={{ top: `${(index + 1) * 10}px` }}
                >
                    <div className="sm:w-2/3  xl:p-[100px] text-center md:text-start transition-all duration-300 text-white origin-top">
                        <h1 className="font-['Outfit'] text-xl md:text-2xl lg:text-4xl font-semibold bg-transparent leading-6 md:leading-9 mb-4">{title}</h1>
                        <p className="font-['Outfit'] text-lg md:text-xl lg:text-2xl 2xl:text-4xl font-normal leading-6 md:leading-8 bg-transparent">{description2}</p>
                    </div>
                    <div className="relative flex  w-2/3 sm:w-1/3 ">
                        <img src={imageUrl} alt={title} className="  object-cover rounded-tl-lg " />
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardsContainer = ({ cards }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const cards = containerRef.current.querySelectorAll('.sticky');
            const data = {
                Location: {},
                ToReduceBy: {},
            };

            cards.forEach((card, index) => {
                const stickyTop = parseInt(window.getComputedStyle(card).top);
                const currentTop = card.getBoundingClientRect().top - 200;
                data.Location[index] = currentTop - stickyTop;
                data.ToReduceBy[index] = 0;
            });

            for (let i = 0; i < cards.length; i++) {
                if (data.Location[i] <= 20) {
                    for (let j = i; j > -1; j--) {
                        data.ToReduceBy[j]++;
                    }
                }
            }

            cards.forEach((card, index) => {
                const innerCard = card.firstElementChild;
                innerCard.style.transform = `scale(${1 - (data.ToReduceBy[index] * 0.10)})`;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex  flex-col items-center justify-center" ref={containerRef}>
            {cards.map((card, index) => (
                <StackingpageshomeCard key={index} {...card} index={index} />
            ))}
        </div>
    );
};

const Stackingpageshome = () => {
    const cardsData = [
        {
            title: "Pyzo",
            description2: "Streamline any evaluation process with our AI-powered evaluator.",
            imageUrl: AIInterviewer,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Gratify",
            description2: "Elevate customer engagement with our rewards and gamification platform that turns loyalty into an experience.",
            imageUrl: gratify2,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Sahay",
            description2: "Intelligent AI-Assistant powered by RAG for Your Banking Needs",
            imageUrl: Sahay,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Esto",
            description2: "Supercharge your marketing and sales efforts with our smart SaaS solution, driving results through data-driven insights.",
            imageUrl: esto2,
            backgroundColor: "rgba(0,0,0)",
        },
        // {
        //     title: "AI Interviewer",
        //     description2: "Streamline your hiring process with our AI-powered interviewing solution.",
        //     imageUrl: AIInterviewer,
        //     backgroundColor: "rgba(0,0,0)",
        // }
    ];

    return (
        <div className="w-full mx-auto md:mt-16  ">
            <div className="grid justify-center gap-4 overflow-hidden">
                <p
                    className="text-xl md:text-3xl font-semibold leading-6 md:leading-9 text-center px-4 "
                    style={{ color: 'var(--Color-2, #0F0F0F)', opacity: '1' }}
                >
                    Add our Secret Ingredients to Your Suite
                </p>
                <p
                    className="text-base md:text-lg font-normal leading-6 md:leading-8 text-center px-4 xl:px-[300px] "
                    style={{ color: 'var(--Color-2, #0F0F0F)', opacity: '0.6' }}
                >
                    Integrate our unique SaaS solutions to boost your product capabilities and deliver exceptional value to your customers.
                </p>
            </div>
            <CardsContainer cards={cardsData} />
            <div className=" mx-3 px-12  text-center m-auto lg:m-0 md:top-[64px]  text-[20px] lg:text-[36px] font-semibold ">
                We have launched 15+ products in 7+ countries
            </div>
        </div>

    );
};

export default Stackingpageshome;
