// import React from "react";
// import Rectangle106 from '../../assets/img/Rectangle106.png';
// import Image1 from "../../assets/img/Samagraport.png"; // Replace with actual path
// import Image2 from "../../assets/img/Port/plum.png"; // Replace with actual path
// import Image3 from "../../assets/img/Port/keka.png"; // Replace with actual path
// import Image4 from "../../assets/img/Port/ISB.png"; // Replace with actual path
// import Image5 from "../../assets/img/Port/Tata Neu.png";
// import { ReactComponent as SlashIcon } from '../../assets/img/slash.svg'; // Adjusted path


// const Portfoliodetailpage = () => {
//     return (
//         <div className=" md:w-full mx-auto py-10 bg-white dark:bg-dark">
//             <div className="md:w-full mx-auto container ">
//                 <div className=" md:w-full  mx-auto justify-items-center mb-8px  ">
//                     <div className="md:W-full relative bg-center bg-center "
//                     >
//                         <div className="md:w-full mx-auto  py-10 shadow-1 dark:shadow-card bg-tg-bg dark:bg-dark-2 sm:px-6 md:px-[0px] md:py-6">
//                             <div className="bg-[#F7F7F7]">
//                                 <ul className="flex items-center text-white py-4 bg-#F9F9F9  hidden md:flex">
//                                     <li className="flex items-center md:ml-[80px]">
//                                         <a
//                                             href="javascript:void(0)"
//                                             className="flex items-center text-gray-500  text-base text-black font-medium hover:text-primary dark:hover:text-primary text-dark dark:text-white"
//                                         >
//                                             Home
//                                         </a>
//                                         <span className=" px-2 text-body-color dark:text-dark-6">
//                                             <SlashIcon width="7" height="12" className="fill-current" />
//                                         </span>
//                                     </li>
//                                     <li className="flex items-center">
//                                         <a
//                                             href="javascript:void(0)"
//                                             className="text-base text-gray-500  font-medium text-black dark:text-dark-6 dark:hover:text-primary hover:text-primary"
//                                         >
//                                             Work
//                                         </a>
//                                         <span className="px-2 text-body-color dark:text-dark-6">
//                                             <SlashIcon width="7" height="12" className="fill-current" />
//                                         </span>
//                                     </li>
//                                     <li className="text-base text-black bold font-medium text-primary">
//                                         Our Product portfolio
//                                     </li>
//                                 </ul>
//                                 <div className=" bg-[#F7F7F7] h-[200px] flex justify-center items-center w-full ">
//                                     <p className="text-black text-center font-outfit text-[28px] px-[50px] md:text-[70px] leading-[35px] md:leading-[80px]">
//                                         <p className="text-[25px] leading-[25px] text-center md:text-[38px] md:leading-[48px]">
//                                             Our Product <span className="font-bold">portfolio</span>
//                                         </p>
//                                     </p>
//                                 </div>
//                             </div>

//                             <div>
//                                 <div className=" w-full   overflow-hidden">
//                                     <div className="flex px-4 flex-wrap-reverse md:flex-nowrap mt-[30px] md:mt-[0px] lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8 md:mt-[90px]">
//                                         <div className="flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mb-[147px] gap-[8px]">
//                                             <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-left">
//                                                 Samagra
//                                             </p>
//                                             <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                 LLM based multilingual chatbots trained on custom datasets that help the end user obtain answers to their queries in a simple conversational manner
//                                             </p>
//                                         </div>
//                                         <div className="relative justify-center rounded-lg overflow-hidden  md:mb-[120px] lg:mb-[100px]  2xl:mb-[50px] 2xl:ml-[150px] xl:ml-[148px] xl:mt-[40px] pb-6 max-w-[540px] max-h-[303.75px]">
//                                             <img src={Image1} alt="Image1" className="object-cover" />
//                                         </div>
//                                     </div>
//                                     <div className=" 2xl:mt-[40px] flex flex-wrap-reverse md:flex-nowrap lg:pl-[108px] bg-[#F9F9F9] lg:pr-[108px] items-center justify-center gap-8">
//                                         <div className="flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mt-[0px] md:mb-[80px] xl:mb-[140px] 2xl:mb-[150px] gap-[8px]">
//                                             <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-left">
//                                                 Plum
//                                             </p>
//                                             <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                 Consumer App Driving Commerce through Community and Content Plum has fast become one of India’s largest and fastest growing D2C Skincare brands. The brand features a fresh line of 100% vegan beauty products that are all about ‘being good’.
//                                                 -Raised Series C at $200m Valuation
//                                             </p>
//                                         </div>
//                                         <div className=" px-4 xl:px-[0px] mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden  xl:ml-[0px] pb-6 md:mb-[120px] xl:mb-[60px] lg:mb-[72px] max-w-[540px] max-h-[303.75px]">
//                                             <img src={Image2} alt="Image2" className="object-cover" />
//                                         </div>
//                                     </div>

//                                     <div className=" 2xl:mt-[70px] flex md:mt-[40px]  px-4 flex-wrap-reverse md:flex-nowrap lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8">
//                                         <div className=" 2xl:mb-[100px] flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mb-[147px] lg:mb-[37px] gap-[8px]">
//                                             <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-left">
//                                                 Keka
//                                             </p>
//                                             <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                 Keka is a leading smart HR software that helps create automated processes for easier management and helps organizations build a engaging and driven culture for better decision making.
//                                                 <br /> - Raised $57mn in Series A  funding
//                                                 <br /> - Valued at nearly $118mn
//                                             </p>
//                                         </div>
//                                         <div className=" mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden md:mb-[150px]  lg:mb-[0px] 2xl:ml-[108px] xl:ml-[148px] pb-6 max-w-[540px] max-h-[303.75px]">
//                                             <img src={Image3} alt="Image3" className="object-cover" />
//                                         </div>
//                                     </div>

//                                     <div className="w-full overflow-hidden  md:mt-[30px]">
//                                         <div className=" 2xl:mt-[70px] lg:mt-[50px] flex bg-[#F9F9F9]  flex-wrap-reverse md:flex-nowrap lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8">
//                                             <div className="flex  flex-col justify-center ml-[26px] mr-[26px] mt-[40px] md:mt-[0px] lg:mb-[90px] md:mb-[80px] xl:mb-[157px] gap-[8px]">
//                                                 <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-left">
//                                                     ISB
//                                                 </p>
//                                                 <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                     An LLM based interview bot that scrapes the user profile and uses GPT 4 to conduct a personalized interview. The candidate profile is fed to the fine-tuned GPT model which conducts an interview and scores the candidate on the basis of a set of pre -defined personality traits
//                                                 </p>
//                                             </div>
//                                             <div className=" px-4 mt-[50px] md:mt-[0px] relative justify-center rounded-lg overflow-hidden md:mb-[65.13px] md:mb-[190px] xl:mb-[100px] 2xl:mb-[50px]  2xl:ml-[100px] xl:ml-[0px] pb-6 max-w-[540px] max-h-[303.75px]">
//                                                 <img src={Image4} alt="Image4" className="object-cover" />
//                                             </div>
//                                         </div>

//                                         <div className=" 2xl:mt-[70px] flex flex-wrap-reverse md:flex-nowrap lg:ml-[108px] lg:mr-[108px] items-center justify-center gap-8 mt-[30px]">
//                                             <div className=" 2xl:mb-[180px] flex flex-col justify-center ml-[26px] mr-[26px]  xl:mb-[147px] gap-[8px]">
//                                                 <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-left">
//                                                     Tata Neu
//                                                 </p>
//                                                 <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                     Powering Tata Neu’s Loyalty and Gamification
//                                                 </p>
//                                             </div>
//                                             <div className=" px-4 relative justify-center rounded-lg overflow-hidden md:ml-[120px] xl:ml-[150px] pb-6 max-w-[540px] max-h-[303.75px]">
//                                                 <img src={Image5} alt="Image5" className="object-cover" />
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                     <div />
//                 </div >
//             </div>
//         </div >
//     );
// };

// export default Portfoliodetailpage;

// import React, { useState } from "react";
// import Image1 from "../../assets/img/Samagraport.png";
// import Image2 from "../../assets/img/Port/plum.png";
// import Image3 from "../../assets/img/Port/keka.png";
// import Image4 from "../../assets/img/Port/ISB.png";
// import Image5 from "../../assets/img/Port/Tata Neu.png";
// import Image6 from "../../assets/img/Port/Enso.png";
// import Image7 from "../../assets/img/Port/Brik itt.png";
// import Image8 from "../../assets/img/Port/Peekup.png";
// import Image9 from "../../assets/img/Port/PeekUP pay.png";
// import Image10 from "../../assets/img/Port/Ypay.png";
// import Image11 from "../../assets/img/Port/Lifetalk.png";
// import Image12 from "../../assets/img/Port/Domino's.png";
// import Image13 from "../../assets/img/Port/Vidyakul.png";
// import Image14 from "../../assets/img/Port/Bitclass.png";
// import Image15 from "../../assets/img/Port/IserveU.png";
// import Image16 from "../../assets/img/Port/Builder.io.png";
// import Image17 from "../../assets/img/Port/Extolabs.png";

// import { ReactComponent as SlashIcon } from '../../assets/img/slash.svg'; // Adjusted path

// const PortfolioDetailPage = () => {
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 5; // Number of items per page
//     const totalItems = 17; // Total number of items (adjust as needed)

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     const getItemsForPage = () => {
//         // Define your items here, using pagination to slice them
//         const items = [
//             { title: "Samagra", description: "LLM based multilingual chatbots trained on custom datasets that help the end user obtain answers to their queries in a simple conversational manner", image: Image1 },
//             { title: "Plum", description: "Consumer App Driving Commerce through Community and Content Plum has fast become one of India’s largest and fastest growing D2C Skincare brands. The brand features a fresh line of 100% vegan beauty products that are all about ‘being good’. Raised Series C at $200m Valuation", image: Image2 },
//             { title: "Keka", description: "Keka is a leading smart HR software that helps create automated processes for easier management and helps organizations build a engaging and driven culture for better decision making. - Raised $57mn in Series A funding - Valued at nearly $118mn", image: Image3 },
//             { title: "ISB", description: "An LLM based interview bot that scrapes the user profile and uses GPT 4 to conduct a personalized interview. The candidate profile is fed to the fine-tuned GPT model which conducts an interview and scores the candidate on the basis of a set of pre-defined personality traits", image: Image4 },
//             { title: "Tata Neu", description: "Powering Tata Neu’s Loyalty and Gamification", image: Image5 },
//             { title: "ENSO", description: "Enso Group is a real estate developer set out on a journey to build high quality and thoughtfully designed spaces that go beyond fulfilling the needs of a buyer, spaces that bring joy in every way to every buyer.", image: Image6 },
//             { title: "Brik itt", description: "Consumer Web App Owner’s App Business Portal Sales Portal India’s pioneer fractional ownership brand with a mission to become the world’s largest holiday home owners community. - 200 + BRIK owners with 20 + properties", image: Image7 },
//             { title: "PeekUp", image: Image8 },
//             { title: "PeekUp Pay", description: "PeekUp Pay is a payment solution company that aims to provide a comprehensive and convenient platform for online payments and business banking in Ph. PeekUp Pay offers various products and services for businesses to accept, process, and disburse payments with ease and efficiency.PeekUp Pay is a one- stop solution for all your online payment and business banking needs in South East Asia.", image: Image9 },
//             { title: "YPay", description: "YPay aims at bringing financial freedom to Gen Z. With facilities like bankless transactions and a numberless card, YPay is the neo-banking solution for kids, pre-teens, schools, and colleges. - Raised Pre Series A Round - 250k + cards in use", image: Image10 },
//             { title: "Lifetalk", description: "Leading health & wellness app with prime focus on holistic wellness via India’s top experts Seed funded with 100k+ current users", image: Image11 },
//             { title: "Domino’s Pizza", description: "Digital customer engagement modules for enterprise retail that helps businesses provide their existing customers with high powered AI & ML rewarding and booking solutions. Smart marketing solution catering to 1M+ monthly customers.- Consumer Loyalty Program - Pieces of Pie", image: Image12 },
//             { title: "Vidyakul", description: "India’s first vernacular e-learning platform and online marketplace that aims to make education more accessible in tier-II, III cities. Allows educators to create online courses and students to gain quality education at affordable prices. 500k + Users | Raised Series A", image: Image13 },
//             { title: "BitClass", description: "Live Learning App | Android, iOS Non-academic live learning and elearning app for tutors and consumers to learn skills, hobbies etc. 1m + Users | Raised Series A", image: Image14 },
//             { title: "iServeU", description: "AEPS, microATM SaaS Full stack financial infrastructure company providing one-stop solution for payment acquiring, issuing, accounts, lending, withdrawal, deposit, etc. User Surveys & UX Recently secured a $13mn funding", image: Image15 },
//             { title: "Builder.io", description: "Helps visually build and optimize web and mobile experiences on your existing sites and apps to speed up your build-measure-learn cycles and drive growth, faster. Series A at $100m Valuation", image: Image16 },
//             { title: "Exto Labs", description: "The Last-Mile Solution For Digitizing Payments & Identity Enabling last mile digital payments for financial inclusion. A highly secure low-cost, easy to use, open, and inclusive blockchain service that provides banks, fintechs, merchants and FMCG with digital platform for multiple online and offline scenarios, including payment, marketing, and identity. RBI Award Winner in Payments Innovation Series A Raised 25m", image: Image17 }
//         ];

//         const startIndex = (currentPage - 1) * itemsPerPage;
//         const endIndex = startIndex + itemsPerPage;
//         return items.slice(startIndex, endIndex);
//     };

//     return (
//         <div className="md:w-full mx-auto py-10 bg-white dark:bg-dark">
//             <div className="md:w-full mx-auto container">
//                 <div className="md:w-full mx-auto justify-items-center mb-8px">
//                     <div className="md:w-full relative bg-center bg-center">
//                         <div className="md:w-full mx-auto py-10 shadow-1 dark:shadow-card bg-tg-bg dark:bg-dark-2 sm:px-6 md:px-[0px] md:py-6">
//                             <div className="bg-[#F7F7F7]">
//                                 <ul className="flex items-center text-white py-4 bg-#F9F9F9 hidden md:flex">
//                                     <li className="flex items-center md:ml-[80px]">
//                                         <a
//                                             href="javascript:void(0)"
//                                             className="flex items-center text-gray-500 text-base text-black font-medium hover:text-primary dark:hover:text-primary text-dark dark:text-white"
//                                         >
//                                             Home
//                                         </a>
//                                         <span className="px-2 text-body-color dark:text-dark-6">
//                                             <SlashIcon width="7" height="12" className="fill-current" />
//                                         </span>
//                                     </li>
//                                     <li className="flex items-center">
//                                         <a
//                                             href="javascript:void(0)"
//                                             className="text-base text-gray-500 font-medium text-black dark:text-dark-6 dark:hover:text-primary hover:text-primary"
//                                         >
//                                             Work
//                                         </a>
//                                         <span className="px-2 text-body-color dark:text-dark-6">
//                                             <SlashIcon width="7" height="12" className="fill-current" />
//                                         </span>
//                                     </li>
//                                     <li className="text-base text-black font-medium text-primary">
//                                         Our Product <span className="font-bold">portfolio</span>
//                                     </li>
//                                 </ul>
//                                 <div className="bg-[#F7F7F7] h-[200px] flex justify-center items-center w-full">
//                                     <p className="text-black text-center font-outfit text-[28px] px-[50px]  md:text-[50px] lg:text-[70px] leading-[35px] md:leading-[80px]">
//                                         Our Product <span className="font-bold">portfolio</span>
//                                     </p>
//                                 </div>
//                             </div>

//                             <div>
//                                 <div className="w-full  overflow-hidden">
//                                     {getItemsForPage().map((item, index) => (
//                                         <div key={index} className={`  flex px-4 flex-wrap-reverse md:flex-nowrap ${index % 2 === 0 ? 'mt-[40px]' : 'mt-[70px]'} lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8`}>
//                                             <div className="flex md:w-[50%] flex-col justify-center md:ml-[26px] md:mr-[26px] mt-[40px] md:mb-[147px] gap-[8px]">
//                                                 <p className="font-outfit md:text-[40px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[50.4px] md:text-start">
//                                                     {item.title}
//                                                 </p>
//                                                 <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
//                                                     {item.description}
//                                                 </p>
//                                             </div>
//                                             {/* <div style={{border:"1px solid red"}} className="relative  justify-center rounded-lg overflow-hidden md:mb-[140px] lg:mb-[100px] 2xl:mb-[50px] 2xl:ml-[150px] xl:ml-[148px] xl:mt-[40px]  max-w-[540px] max-h-[303.75px]"> */}
//                                             <img src={item.image} alt={item.title} className="relative mt-[20px] md:mt-[0px] justify-center rounded-lg overflow-hidden md:mb-[140px] lg:mb-[100px] 2xl:mb-[50px] 2xl:ml-[150px] xl:ml-[148px] xl:mt-[40px]  w-[540px] " />
//                                             {/* </div> */}
//                                         </div>
//                                     ))}
//                                 </div>
//                                 <Pagination
//                                     totalItems={totalItems}
//                                     itemsPerPage={itemsPerPage}
//                                     currentPage={currentPage}
//                                     onPageChange={handlePageChange}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div />
//                 </div>
//             </div>
//         </div>
//     );
// };

// const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
//     const totalPages = Math.ceil(totalItems / itemsPerPage);

//     const handlePageChange = (page) => {
//         if (page >= 1 && page <= totalPages) {
//             onPageChange(page);
//         }
//     };

//     return (
//         <div className="flex items-center justify-center mt-8">
//             <button
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//                 className="px-4 py-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
//             >
//                 &lt; {/* Previous Arrow */}
//             </button>
//             {[...Array(totalPages)].map((_, index) => (
//                 <button
//                     key={index}
//                     onClick={() => handlePageChange(index + 1)}
//                     className={`px-4 py-2 mx-1 ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'text-gray-500 hover:text-gray-700'}`}
//                 >
//                     {index + 1}
//                 </button>
//             ))}
//             <button
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={currentPage === totalPages}
//                 className="px-4 py-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
//             >
//                 &gt; {/* Next Arrow */}
//             </button>
//         </div>
//     );
// };

// export default PortfolioDetailPage;

import React, { useState } from "react";
import Image1 from "../../assets/img/Samagraport.png";
import Image2 from "../../assets/img/Port/plum.png";
import Image3 from "../../assets/img/Port/keka.png";
import Image4 from "../../assets/img/Port/ISB.png";
import Image5 from "../../assets/img/Port/Tata Neu.png";
import Image6 from "../../assets/img/Port/Enso.png";
import Image7 from "../../assets/img/Port/Brik itt.png";
import Image8 from "../../assets/img/Port/Peekup.png";
import Image9 from "../../assets/img/Port/PeekUP pay.png";
import Image10 from "../../assets/img/Port/Ypay.png";
import Image11 from "../../assets/img/Port/Lifetalk.png";
import Image12 from "../../assets/img/Port/Domino's.png";
import Image13 from "../../assets/img/Port/Vidyakul.png";
import Image14 from "../../assets/img/Port/Bitclass.png";
import Image15 from "../../assets/img/Port/IserveU.png";
import Image16 from "../../assets/img/Port/Builder.io.png";
import Image17 from "../../assets/img/Port/Extolabs.png";

import { ReactComponent as SlashIcon } from '../../assets/img/slash.svg';

const PortfolioDetailPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalItems = 17;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItemsForPage = () => {
        const items = [
            { title: "LLM-Powered Multilingual application for Query Resolution", description: "Our advanced LLM-based multilingual application is trained on custom datasets, empowering users to effortlessly obtain precise answers to their queries through engaging and intuitive conversational interactions.", image: Image1 },
            { title: "Building tech for India’s Leading Vegan Skincare Brand", description: "Plum has rapidly emerged as one of India’s largest and fastest-growing D2C skincare brands, offering a vibrant line of 100% vegan beauty products centered around the ethos of 'being good.' With a successful Series C funding round, the brand has achieved a remarkable valuation of $200 million.", image: Image2 },
            { title: "Built tech for Keka, aSmart HR Software Transforming Workforce Management", description: "Keka is a leading smart HR software that automates processes for easier management, helping organizations foster an engaging and driven culture for better decision-making. We recently raised $57 million in Series A funding, valuing the company at nearly $118 million.", image: Image3 },
            { title: "Personalized Candidate Assessments for Profile-Based Scoring for ISB", description: "ISB features an LLM-based interview application that analyzes user profiles and utilizes GPT-4 to conduct personalized interviews. We use candidate profiles in a fine-tuned GPT model, which conducts interviews and scores candidates based on predefined personality traits.", image: Image4 },
            { title: "Loyalty and Gamification Platform for Personalized Rewards", description: "Tata Neu powers loyalty and gamification, enhancing customer engagement through innovative reward systems. Our platform offers personalized experiences that encourage user interaction and foster brand loyalty, driving growth and customer satisfaction across Tata’s diverse offerings.", image: Image5 },
            { title: "Redefining Real Estate with Thoughtfully Designed Spaces", description: "Enso Group is a real estate developer committed to creating high-quality, thoughtfully designed spaces that exceed buyer expectations. We deliver properties that bring joy and fulfillment to every buyer, enhancing their living experience in meaningful ways.", image: Image6 },
            { title: "India's Leading Fractional Ownership Platform for Holiday Homes", description: "Brik itt is India's pioneer fractional ownership brand, aiming to build the world's largest holiday home owners community. Our consumer web app includes an owner's app, business portal, and sales portal. Currently, we have over 200 BRIK owners and more than 20 properties, making vacation ownership accessible and enjoyable.", image: Image7 },
            { title: "Revolutionizing Transportation with Sustainable, Secure, and Customer-Centric Solutions", description: "PeekUp is a technology startup revolutionizing the transportation landscape. We are dedicated to delivering innovative, sustainable, and secure solutions, all designed with a customer-centric approach that empowers individuals and businesses to move seamlessly across geographies.", image: Image8 },
            { title: "Simplifying Online Payments and Business Banking", description: "PeekUp Pay is a payment solution company offering a comprehensive platform for online payments and business banking in the Philippines. We simplify financial transactions by enabling businesses to easily accept, process, and disburse payments, making us the go-to solution for all online payment needs in Southeast Asia.", image: Image9 },
            { title: "Financial Freedom Through Bankless Transactions and Numberless Cards", description: "YPay empowers Gen Z with financial freedom through bankless transactions and a numberless card. Our neo-banking solution caters to kids, pre-teens, schools, and colleges. Having raised a Pre-Series A round, we currently have over 250,000 cards in use, providing young users with tailored financial tools.", image: Image10 },
            { title: "Empowering Holistic Wellness with Expert Guidance and Personalized Health Solutions", description: "Lifetalk is a leading health and wellness app focusing on holistic wellness through India's top experts. With over 100,000 users and seed funding, we empower individuals on their wellness journeys with personalized guidance.", image: Image11 },
            { title: "AI-Driven Digital Engagement and Loyalty Solutions for Dominos Retail", description: `Domino's Pizza provides digital customer engagement modules for enterprise retail, utilizing AI and machine learning for rewards and booking. Our smart marketing solutions serve over 1 million monthly customers, while our "Pieces of Pie" loyalty program fosters customer loyalty and repeat business.`, image: Image12 },
            { title: "Revolutionizing Education with Affordable Vernacular E-Learning", description: "Vidyakul is India’s first vernacular e-learning platform aimed at making education accessible in tier-II and III cities. We empower educators to create online courses while providing quality education at affordable prices. With over 500,000 users, we secured Series A funding.", image: Image13 },
            { title: "Connecting Tutors and Learners for Non-Academic Education", description: "BitClass is a live learning app for Android and iOS, connecting tutors and learners for non-academic education in skills and hobbies. With over 1 million users, we are building a vibrant community and recently raised Series A funding.", image: Image14 },
            { title: "Comprehensive SaaS Solution for Streamlined Financial Infrastructure and Transaction Management", description: "iServeU is a full-stack financial infrastructure company offering a comprehensive SaaS solution for payment acquiring, issuing, accounts, lending, and more. Our platform simplifies financial services for businesses to manage transactions efficiently. We recently secured $13 million in funding.", image: Image15 },
            { title: "Accelerating Growth with Visual Web and Mobile Experience creation and optimization", description: "Builder.io lets you visually create and optimize web and mobile experiences on existing sites and apps, accelerating growth with minimal coding. We recently completed a Series A funding round, reaching a valuation of $100 million.", image: Image16 },
            { title: "Empowering Financial Inclusion with Secure, Low-Cost Digital Payments and Identity Solutions", description: "Exto Labs is a last-mile solution for digitizing payments and identity, enhancing financial inclusion through secure, low-cost digital transactions. Our open blockchain service offers banks, fintechs, merchants, and FMCG companies a versatile platform for payments and identity verification. We recently raised $25 million to expand our impact.", image: Image17 }
        ];

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return items.slice(startIndex, endIndex);
    };

    const specialBackgroundItems = ["Plum", "ISB", "Brik itt", "Domino's Pizza", "Vidyakul", "Exto Labs"];

    return (
        <div className="md:w-full mx-auto py-10 bg-white dark:bg-dark">
            <div className="md:w-full mx-auto container">
                <div className="md:w-full mx-auto justify-items-center mb-8px">
                    <div className="md:w-full relative bg-center bg-center">
                        <div className="md:w-full mx-auto py-10 shadow-1 dark:shadow-card bg-tg-bg dark:bg-dark-2 sm:px-6 md:px-[0px] md:py-6">
                            <div className="bg-[#F7F7F7]">
                                {/* ... (header content remains unchanged) ... */}
                            </div>

                            <div>
                                <div className="w-full overflow-hidden">
                                    {getItemsForPage().map((item, index) => (
                                        <div
                                            key={index}
                                            className={`flex px-4 flex-wrap-reverse md:flex-nowrap ${index % 2 === 0 ? 'mt-[40px]' : 'mt-[70px]'} lg:pl-[108px] lg:pr-[108px] items-center justify-center gap-8`}
                                            style={{ backgroundColor: specialBackgroundItems.includes(item.title) ? "#F9F9F9" : "transparent" }}
                                        >
                                            <div className="flex md:w-[50%] flex-col justify-center md:ml-[26px] md:mr-[26px] mt-[40px] md:mb-[147px] gap-[8px]">
                                                <p className="font-outfit md:text-[30px] text-[20px] text-center leading-[25.2px] font-bold md:leading-[40.4px] md:text-start">
                                                    {item.title}
                                                </p>
                                                <p className="font-outfit md:text-[20px] text-[14px] leading-[17.64px] text-center font-normal md:leading-[25.2px] md:text-left text-[#7A7A7A]">
                                                    {item.description}
                                                </p>
                                            </div>
                                            <img src={item.image} alt={item.title} className="relative mt-[20px] md:mt-[0px] justify-center rounded-lg overflow-hidden md:mb-[140px] lg:mb-[100px] 2xl:mb-[50px] 2xl:ml-[150px] xl:ml-[148px] xl:mt-[40px] w-[540px]" />
                                        </div>
                                    ))}
                                </div>
                                <Pagination
                                    totalItems={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex items-center justify-center mt-8">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
            >
                &lt; {/* Previous Arrow */}
            </button>
            {[...Array(totalPages)].map((_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-4 py-2 mx-1 ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'text-gray-500 hover:text-gray-700'}`}
                >
                    {index + 1}
                </button>
            ))}
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
            >
                &gt; {/* Next Arrow */}
            </button>
        </div>
    );
};

export default PortfolioDetailPage;