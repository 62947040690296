import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styles from "./index.module.css";

const PhoneNumberInput = ({ value, onchange }) => {
  return (
    <div className={styles.phoneInputContainer}>
      <PhoneInput
        placeholder="Mobile Number"
        defaultCountry="IN"
        value={value}
        onChange={onchange}
        arrowIconStyle={{ tintColor: 'red' }}
      />
    </div>
  );
};

export default PhoneNumberInput;
