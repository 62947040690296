import React from "react";
import Breadcrumb from "../components/gratify/Breakcrumb";
import BackToTop from "../components/gratify/BackToTop";
import FeaturesSection from "../components/gratify/interactivejourney";
import Gratifyscales from "../components/gratify/gratifyscales";
import TransformSection from "../components/gratify/readytotransform";
import GratifySection from "../components/gratify/wholovesgratify";
import GratifyPartner from "../components/gratify/gratifysection";
import Card from "../components/gratify/Card";
import { Helmet } from 'react-helmet';


const Gratify = () => {
  return (
    <>
      <Helmet>
        <title>Gratify | Boost Customer Engagement with Our AI-Powered Platform</title>
        <meta
          name="description"
          content="Elevate your business with Gratify, our innovative rewards and gamification platform. Drive customer loyalty, increase engagement, and boost sales through personalized rewards and engaging experiences."
        />
        <meta
          property="og:title"
          content="Gratify | Boost Customer Engagement with Our AI-Powered Platform"
        />
        <meta
          property="og:description"
          content="Elevate your business with Gratify, our innovative rewards and gamification platform. Drive customer loyalty, increase engagement, and boost sales through personalized rewards and engaging experiences."
        />
      </Helmet>
      <div  >
        <Breadcrumb />
        <FeaturesSection />
        <Gratifyscales />
        <TransformSection />
        <GratifySection />
        <Card />
        <GratifyPartner />
        <BackToTop />
      </div >
    </>

  );
};

export default Gratify;
