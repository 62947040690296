
// import React, { useEffect, useState } from 'react';
// import Research from "../../assets/img/Research1.png";
// import Strategize from "../../assets/img/Strategize.png";
// import Innovate from "../../assets/img/innovate.png";
// import Create from "../../assets/img/Create.png";
// import Delight from "../../assets/img/Delight.png";
// import Analyze1 from "../../assets/img/Analyze1.png";

// const Speedsection = () => {
//     const [activeIndex, setActiveIndex] = useState(0);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveIndex((prevIndex) => (prevIndex + 1) % 6); // Cycle through the 6 items
//         }, 2000); // Adjust time interval as needed

//         return () => clearInterval(interval);
//     }, []);

//     const data = [
//         { img: Research, title: "Research", desc: "Gather the perfect ingredients." },
//         { img: Strategize, title: "Strategize", desc: "Squeeze the best recipe." },
//         { img: Innovate, title: "Innovate", desc: "Add a dash of creativity." },
//         { img: Create, title: "Create", desc: "Mix it all together." },
//         { img: Delight, title: "Delight", desc: "Taste the refreshing outcome." },
//         { img: Analyze1, title: "Analyze", desc: "Perfect the recipe for future batches." },
//     ];

//     return (
//         <div className="w-[90%] mx-auto mt-[20px] mb-[42px]">
//             <p className="h-[8vh] text-Outfit text-[20px] md:text-[38px] font-semibold mt-[35px] md:mt-[65px] leading-[25.2px] ml-[28.8px] mr-[26px] md:ml-[50px] md:mr-[50px] md:mb-[50px] md:leading-[47.88px] text-[#0F0F0F] lg:text-5xl text-center md:block">
//                 Speed and Excellence: The Es Magico Brew
//             </p>
//             <div className="grid w-full  mx-auto mt-[35px] md:mt-[107px]   md:gap-[23px] md:flex justify-center items-center">
//                 {data.map((item, index) => (
//                     <div
//                         key={index}
//                         className={` md:h-[33vh] relative flex md:flex-wrap md:w-[13.6vw] gap-[8px] md:pl-[1vw] md:pt-[14px] ${activeIndex === index ? 'z-10' : ''}`}
//                         style={{
//                             overflow: 'hidden', // Ensure the popup effect is contained
//                         }}
//                     >
//                         <img
//                             src={item.img}
//                             alt={item.title}
//                             className={`w-[9vw] h-[9vh] md:w-[4vw] opacity-100 ml-[1vw] md:pt-[0px] md:pb-[0px] pt-[14px] pb-[15px] ${activeIndex === index ? 'animate-popup' : ''}`}
//                             style={{
//                                 filter: activeIndex === index ? 'hue-rotate(190deg) brightness(1.2)' : 'none', // Apply blue tint and brightness boost
//                                 transition: 'filter 0.5s ease',
//                             }}
//                         />
//                         {activeIndex === index && (
//                             <div
//                                 className="absolute inset-0 bg-[#EAF1FF] opacity-50 rounded-lg"
//                                 style={{ top: 0, left: 0 }}
//                             />
//                         )}
//                         <div className='flex-wrap text-[#0F0F0F]'>
//                             <p className="font-['Outfit'] text-[20px] font-semibold leading-[38.33px]">
//                                 {item.title}
//                             </p>
//                             <p className="font-['Outfit'] text-[16px] font-normal leading-[25.2px]">
//                                 {item.desc}
//                             </p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <style jsx>{`
//                 @keyframes popup {
//                     0% {
//                         transform: scale(1);
//                         filter: hue-rotate(0deg) brightness(1);
//                     }
//                     50% {
//                         transform: scale(1.2);
//                         filter: hue-rotate(190deg) brightness(1.2); /* Blue tint */
//                     }
//                     100% {
//                         transform: scale(1);
//                         filter: hue-rotate(190deg) brightness(1.2); /* Blue tint */
//                     }
//                 }

//                 .animate-popup {
//                     animation: popup 2s ease-in-out;
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default Speedsection;

// import React, { useEffect, useState } from 'react';
// import Lottie from 'react-lottie';
// // import ResearchAnimation from "../../assets/animations/researchAnimation.json";
// // import StrategizeAnimation from "../../assets/animations/strategizeAnimation.json";
// // import InnovateAnimation from "../../assets/animations/innovateAnimation.json";
// // import CreateAnimation from "../../assets/animations/createAnimation.json";
// import wizard from "../../assets/img/wizard.json";
// import cauldron from "../../assets/img/cauldron.json";
// import bubbles from "../../assets/img/bubbles.json";
// import magicwand from "../../assets/img/magicwand.json";
// import Mixing from "../../assets/img/Mixing.json";
// import FinalOutput from "../../assets/img/FinalOutput.json";

// // import DelightAnimation from "../../assets/animations/delightAnimation.json";
// // import AnalyzeAnimation from "../../assets/animations/analyzeAnimation.json";

// const Speedsection = () => {
//     const [activeIndex, setActiveIndex] = useState(0);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveIndex((prevIndex) => (prevIndex + 1) % 6); // Cycle through the 6 items
//         }, 2000); // Adjust time interval as needed

//         return () => clearInterval(interval);
//     }, []);

//     const data = [
//         { animation: wizard, title: "Research", desc: "Gather the perfect ingredients." },
//         { animation: cauldron, title: "Strategize", desc: "Squeeze the best recipe." },
//         { animation: bubbles, title: "Innovate", desc: "Add a dash of creativity." },
//         { animation: magicwand, title: "Create", desc: "Mix it all together." },
//         { animation: Mixing, title: "Delight", desc: "Taste the refreshing outcome." },
//         { animation: FinalOutput, title: "Analyze", desc: "Perfect the recipe for future batches." },
//     ];

//     return (
//         <div className="w-[90%] mx-auto mt-[20px] mb-[42px]">
//             <p className="h-[8vh] text-Outfit text-[20px] md:text-[38px] font-semibold mt-[35px] md:mt-[65px] leading-[25.2px] ml-[28.8px] mr-[26px] md:ml-[50px] md:mr-[50px] md:mb-[50px] md:leading-[47.88px] text-[#0F0F0F] lg:text-5xl text-center md:block">
//                 Speed and Excellence: The Es Magico Brew
//             </p>
//             <div className="grid w-full mx-auto mt-[35px] md:mt-[107px] md:gap-[23px] md:flex justify-center items-center">
//                 {data.map((item, index) => (
//                     <div
//                         key={index}
//                         className={`md:h-[33vh] relative flex md:flex-wrap md:w-[13.6vw] gap-[8px] md:pl-[1vw] md:pt-[14px] ${activeIndex === index ? 'z-10' : ''}`}
//                         style={{
//                             overflow: 'hidden', // Ensure the popup effect is contained
//                         }}
//                     >
//                         <div
//                             className={`w-[9vw] h-[9vh] md:w-[4vw] ${activeIndex === index ? 'animate-popup' : ''}`}
//                         >
//                             <Lottie
//                                 options={{
//                                     loop: true,
//                                     autoplay: true,
//                                     animationData: item.animation,
//                                 }}
//                                 height={50}
//                                 width={50}
//                             />
//                         </div>
//                         {activeIndex === index && (
//                             <div
//                                 className="absolute inset-0 bg-[#EAF1FF] opacity-50 rounded-lg"
//                                 style={{ top: 0, left: 0 }}
//                             />
//                         )}
//                         <div className='flex-wrap text-[#0F0F0F]'>
//                             <p className="font-['Outfit'] text-[20px] font-semibold leading-[38.33px]">
//                                 {item.title}
//                             </p>
//                             <p className="font-['Outfit'] text-[16px] font-normal leading-[25.2px]">
//                                 {item.desc}
//                             </p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <style jsx>{`
//                 @keyframes popup {
//                     0% {
//                         transform: scale(1);
//                         filter: hue-rotate(0deg) brightness(1);
//                     }
//                     50% {
//                         transform: scale(1.2);
//                         filter: hue-rotate(190deg) brightness(1.2); /* Blue tint */
//                     }
//                     100% {
//                         transform: scale(1);
//                         filter: hue-rotate(190deg) brightness(1.2); /* Blue tint */
//                     }
//                 }

//                 .animate-popup {
//                     animation: popup 2s ease-in-out;
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default Speedsection;

import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import wizard from "../../assets/img/wizard.json";
import cauldron from "../../assets/img/cauldron.json";
import bubbles from "../../assets/img/bubbles.json";
import magicwand from "../../assets/img/magicwand.json";
import Mixing from "../../assets/img/Mixing.json";
import FinalOutput from "../../assets/img/FinalOutput.json";

const Speedsection = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const data = [
        { animation: wizard, title: "Research", desc: "Gather the perfect ingredients." },
        { animation: cauldron, title: "Strategize", desc: "Squeeze the best recipe." },
        { animation: bubbles, title: "Innovate", desc: "Add a dash of creativity." },
        { animation: magicwand, title: "Create", desc: "Mix it all together." },
        { animation: Mixing, title: "Delight", desc: "Taste the refreshing outcome." },
        { animation: FinalOutput, title: "Analyze", desc: "Perfect the recipe for future batches." },
    ];

    return (
        <div className="w-full  mx-auto mt-[20px] mb-[42px] relative overflow-hidden">
            <div className="blue-line"></div>
            <p className="h-[8vh] text-Outfit text-[24px] md:text-[38px] font-semibold mt-[35px] md:mt-[65px] leading-[25.2px] ml-[28.8px] mr-[26px] md:ml-[50px] md:mr-[50px] md:mb-[50px] md:leading-[47.88px] text-[#0F0F0F] lg:text-5xl text-center md:block">
                Speed and Excellence: The Es Magico Brew
            </p>
            <div className="grid w-full mx-auto gap-[26px] md:flex-row mt-[35px] md:mt-[107px] md:gap-[23px] md:flex justify-center items-center">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`md:h-[25vh] lg:h-[33vh] xl:h-[230px] 2xl:h-[29vh] relative flex md:flex-wrap md:w-[13.6vw] gap-[8px] md:pl-[1vw] md:pt-[14px] ${activeIndex === index ? 'active' : ''}`}
                    >
                        <div className={`w-[9vw] h-[9vh] md:w-[7vw]  lg:w-[7vw]  xl:w-[7vw]  2xl:w-[8vw]  ${activeIndex === index ? 'animate-popup' : 'icon-inactive'}`}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: item.animation,
                                }}
                                height="90%"
                                width="90%"
                                opacity={15}

                            />
                        </div>
                        {activeIndex === index && (
                            <div className="absolute inset-0 bg-[#EAF1FF] opacity-30 rounded-lg " />
                        )}
                        <div className=" md:h-[17vh] gap-[0] lg:h-[8vh] ml-[19px] md:ml-[0px] flex-wrap text-content">
                            <p className="font-Outfit text-[20px] font-semibold leading-[38.33px] text-[#0F0F0F]">
                                {item.title}
                            </p>
                            <p className="font-Outfit text-[16px] font-normal leading-[25.2px] text-[#0F0F0F]">
                                {item.desc}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <style jsx>{`
                @keyframes popup {
                    0% { transform: scale(1); opacity: 2; }
                    50% { transform: scale(1.2); opacity: 2; }
                    100% { transform: scale(1); opacity: 2; }
                }

                .animate-popup {
                    animation: popup 2s ease-in-out;
                }

                .icon-inactive {
                    opacity: 5;
                    transition: opacity 0.3s ease-in-out;
                }

                .text-content {
                    color: #0F0F0F !important;
                }

                .active .text-content {
                    z-index: 20;
                    position: relative;
                }

              
                @media (min-width: 768px) {
                    .blue-line {
                        position: static;
                        height: 2px;
                        width: 50px;
                        margin-left: 50px;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Speedsection;