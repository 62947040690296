import React from "react";
import BackToTop from "../components/gratify/BackToTop";
import BreadcrumbEsto from "../components/Esto/BreadcrumEsto";
import FeaturesSectionEsto from "../components/Esto/FeatureSectionEsto";
import BuildForCollab from "../components/Esto/BuiltForCollab";
import TransformSectionEsto from "../components/Esto/TransformSectionEsto";
import GratifySectionesto from "../components/Esto/wholovesgratifyesto";
import Estosection from "../components/Esto/Estosection";
import EstoSection1 from "../components/Esto/EstoSection1";
import CardEsto from "../components/Esto/CardEsto";
import { Helmet } from 'react-helmet';

const Esto = () => {
    return (
        <>
            <Helmet>
                <title>Esto | Optimize Your Marketing and Sales with AI-Powered Solutions</title>
                <meta
                    name="description"
                    content="Streamline your marketing and sales processes with Esto, our intelligent platform. Leverage AI-driven insights to improve lead generation, customer acquisition, and retention."
                />
                <meta
                    property="og:title"
                    content="Esto | Optimize Your Marketing and Sales with AI-Powered Solutions"
                />
                <meta
                    property="og:description"
                    content="Streamline your marketing and sales processes with Esto, our intelligent platform. Leverage AI-driven insights to improve lead generation, customer acquisition, and retention."
                />
            </Helmet>
            <div   >
                <BreadcrumbEsto />
                <FeaturesSectionEsto />
                <BuildForCollab />
                <Estosection />
                <TransformSectionEsto />
                <GratifySectionesto />
                <CardEsto />
                <EstoSection1 />
                <BackToTop />
            </div >
        </>

    );
};

export default Esto;