import React from "react";
import { motion } from "framer-motion";

const TopHeading = () => {
  return (
    <div className="mb-10 pb-20 lg:pb-[220px] relative overflow-hidden">
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.5,
          duration: window.innerWidth <= 780 ? 1.2 : 0.9,
          backcolor: "#FFFFFF",
        }}
      >
        <h1 className="font-light text-4xl lg:text-6xl pt-36 lg:pt-52 text-center">
          We <span className="font-bold">empower businesses</span>
        </h1>
        <h1 className="font-light text-4xl lg:text-6xl text-center mb-5 lg:mb-12">
          with game-changing SaaS
        </h1>
      </motion.div>
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ delay: 2.3, duration: 1 }}
      >
        <p className="text-center font-normal text-base lg:text-3xl hidden lg:block">
          Simplify, automate, and transform with our powerful
        </p>
        <p className="text-center font-normal text-base lg:text-3xl hidden lg:block">
          solutions.
        </p>

        <p className="text-center font-normal text-base lg:text-3xl block lg:hidden">
          Simplify, automate, and transform with our powerful
        </p>
        <p className="text-center font-normal text-base lg:text-3xl block lg:hidden">
          solutions.
        </p>
      </motion.div>
    </div>
  );
};

export default TopHeading;
