import React from 'react';
import Blackbg from '../../assets/img/Blackbg.png';
import Analytics from '../../assets/img/Analytics.png';

const GratifyPartner = () => {
    return (
        <div className=" w-auto" style={{
            backgroundImage: `url(${Blackbg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            maxWidth: '100%',
            overflow: 'hidden'
        }}>
            <div className="mx-auto flex flex-col items-center ml-[45px] mr-[45px] lg:ml-[230px] lg:mr-[230px]">
                <div className="flex flex-col items-center gap-[15px] mt-[57.78px]">
                    <p className="font-outfit text-[24px] text-center md:text-[45px] font-semibold leading-[30px] md:leading-[40px] lg:leading-[63px] text-[#FDFDFD]">
                        Gratify: Your Partner in User Happiness
                    </p>
                    <p className="w-full h-[60px] font-outfit text-[14px] md:text-[24px] font-normal leading-[17px]  md:leading-[30.24px] text-center text-[#FDFDFD]">
                        Unlock the power of gamification and build lasting relationships with your users. Watch your brand loyalty and sales soar!
                    </p>
                </div>
                <button className="text-white flex items-center justify-center mb-[30px] rounded-[63px] border border-white mt-[40px] md:mt-[45.9px]">
                    <p className="text-white text-center text-[18px] font-bold py-2 px-6 ">
                        Start your journey today!
                    </p>
                </button>
            </div>

            <div className='flex   justify-center items-center'>
                < img
                    src={Analytics}
                    alt="Analytics"
                    className=" object-cover max-w-none w-1/2 md:w-auto  object-contain  w-[80%] h-auto "
                />
            </div>
        </div >

    );
};

export default GratifyPartner;
