import React, { useEffect, useState } from "react";
import TestimonialSlider from "../../utils/TestimonialSlider";
import Pawfect from "../../assets/Testimonial/Pawfect.png";
import mainImg from "../../assets/Testimonial/TestiMainImg.png";
import samagraBg from "../../assets/img/samagraBg.png";
import peekupBg from "../../assets/img/peekupBg.png";
import ladyImg from "../../assets/Testimonial/ladyTesti.png";
import testimonialImg from "../../assets/Testimonial/testimonialImg.png";
import karanImg from "../../assets/img/karan_img.png";
import saumyaImg from "../../assets/img/saumya_kumar_img.png";
import chiragImg from "../../assets/img/chirag_shah_img.png";
import daveImg from "../../assets/img/dave_almarinez_img.png";
import samagraLogo from "../../assets/img/testimonial/samagraLogo.png";
import iviLogo from "../../assets/img/testimonial/iviLogo.png";
import bookmyshowIcon from "../../assets/img/testimonial/bookmyshowIcon.png";
import pickupIcon from "../../assets/img/testimonial/peekupIcon.png";

const bgImages = [mainImg, Pawfect, peekupBg, samagraBg];

const testimonials = [
  {
    name: "Saumya Kumar",
    designation: "Director @I-Venture",
    message:
      "Es Magico's IVI-ISB Chatbot has transformed our candidate filtering process. The AI's ability to ask personalized, human-like questions and provide accurate scores is remarkable. Multilingual and voice-enabled, it even helped us reach Hindi-speaking candidates. Delivered in just six weeks, it's exceeded all our expectations!",
    image: saumyaImg,
    companyLogo: iviLogo,
  },
  {
    name: "Chirag Shah",
    designation: "Assistant General Manager Marketing ",
    message:
      "It was good. The amount of work we did in a short span is commendable. The project had its ups and downs, but overall, it was a decent experience.",
    image: chiragImg,
    companyLogo: bookmyshowIcon,
  },
  {
    name: "Dave Almarinez",
    designation: "Founder",
    message:
      "They are an agile team that take all matters very seriously and put a lot of hard work as well.",
    image: daveImg,
    companyLogo: pickupIcon,
  },
  {
    name: "Karan Trehan",
    designation: "Senior Engineering Manager",
    message:
      "The proactiveness of the team has been refreshing. The culture is driven top down with the leadership team also being extremely proactive and understanding. Updation of scope is a reality of life in tech and the team has always been open to working with us on making things possible instead of finding excuses/complaints.",
    image: karanImg,
    companyLogo: samagraLogo,
  },
];

export default function Testimonial() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % testimonials.length;
      setCurrentSlide(nextSlide);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentSlide]);
  return (
    <div className="w-full h-[680px] md:h-[700px]   overflow-hidden lg:mx-0 mb-24 mx-auto  ">
      <h1 className=" #0F0F0F text-[38px]  lg:text-5xl font-Outfit  text-center my-10  lg:mb-16 lg:mt-20 mx-8 ">
        Building Success Stories Together
      </h1>
      <div className=" w-full  mx-auto ">
        <TestimonialSlider
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          testimonials={testimonials}
        />
      </div>
    </div>
  );
}



