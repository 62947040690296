import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const windowWidth = document.documentElement.clientWidth;

const PowerInside = () => {
  const refs = [
    {
      ref: useRef(null),
      heading: "Tech Architecture",
      text: "We architect you product for scale and power",
    },
    {
      ref: useRef(null),
      heading: "Database Design",
      text: "We optimize your data for lightning-fast retrieval ",
    },
    {
      ref: useRef(null),
      heading: "AI and Machine Learning Integration",
      text: "We integrate cutting-edge AI for intelligent features",
    },
    {
      ref: useRef(null),
      heading: "Frontend Development",
      text: "We build beautiful, engaging user interfaces",
    },
    {
      ref: useRef(null),
      heading: "Backend Development",
      text: "We power your product with robust backend development",
    },
    {
      ref: useRef(null),
      heading: "Third-party Integrations",
      text: "We connect your product to the tools it needs",
    },
    {
      ref: useRef(null),
      heading: "Quality Assurance and Testing ",
      text: "We rigorously test your product for a flawless user experience",
    },
    {
      ref: useRef(null),
      heading: "Deployment",
      text: "We ensure a seamless deployment to your users",
    },
  ];

  const controls = [
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
  ];

  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls[0].start({
            x:
              windowWidth <= 1030
                ? "-20vw"
                : windowWidth <= 1280
                ? "-19vw"
                : windowWidth <= 1370
                ? "-16vw"
                : windowWidth <= 1445
                ? "-15vw"
                : "-15vw",
            y: windowWidth <= 1030 ? "-13vh" : "-13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[1].start({
            x:
              windowWidth <= 1030
                ? "21vw"
                : windowWidth <= 1280
                ? "22vw"
                : windowWidth <= 1370
                ? "22vw"
                : windowWidth <= 1445
                ? "21vw"
                : "18vw",
            y: windowWidth <= 1030 ? "-13vh" : "-13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[2].start({
            x:
              windowWidth <= 1030
                ? "-18vw"
                : windowWidth <= 1280
                ? "-19vw"
                : windowWidth <= 1370
                ? "-17vw"
                : windowWidth <= 1445
                ? "-16vw"
                : "-14vw",
            y: windowWidth <= 1030 ? "0vh" : "0vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[3].start({
            x:
              windowWidth <= 1030
                ? "24vw"
                : windowWidth <= 1280
                ? "22vw"
                : windowWidth <= 1370
                ? "21vw"
                : windowWidth <= 1445
                ? "20vw"
                : "20vw",
            y: windowWidth <= 1030 ? "0vh" : "0vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[4].start({
            x:
              windowWidth <= 1030
                ? "-19vw"
                : windowWidth <= 1280
                ? "-19vw"
                : windowWidth <= 1370
                ? "-17vw"
                : windowWidth <= 1445
                ? "-16vw"
                : "-14vw",
            y: windowWidth <= 1030 ? "13vh" : "13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[5].start({
            x:
              windowWidth <= 1030
                ? "25vw"
                : windowWidth <= 1280
                ? "24vw"
                : windowWidth <= 1370
                ? "23vw"
                : windowWidth <= 1445
                ? "22vw"
                : "22vw",
            y: windowWidth <= 1280 ? "13vh" : "13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[6].start({
            x:
              windowWidth <= 1030
                ? "-20vw"
                : windowWidth <= 1280
                ? "-19vw"
                : windowWidth <= 1370
                ? "-16vw"
                : windowWidth <= 1445
                ? "-15vw"
                : "-13vw",
            y: windowWidth <= 1030 ? "24vh" : "26vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[7].start({
            x:
              windowWidth <= 1030
                ? "26vw"
                : windowWidth <= 1280
                ? "26vw"
                : windowWidth <= 1370
                ? "26vw"
                : windowWidth <= 1445
                ? "25vw"
                : "24vw",
            y: windowWidth <= 1040 ? "24vh" : "26vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    refs.forEach((item) => {
      if (item.ref.current) {
        observer.observe(item.ref.current);
      }
    });

    return () => {
      refs.forEach((item) => {
        if (item.ref.current) {
          observer.unobserve(item.ref.current);
        }
      });
    };
  }, [controls, refs]);

  return (
    <div className="mb-[120px] px-5 w-[90%] m-auto relative">
      <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center">
        ...and powerful on the inside
      </h1>
      <div className="relative w-full h-[55vh] flex justify-center items-center">
        {refs.map((item, index) => (
          <motion.div
            key={index}
            ref={item.ref}
            initial={{ x: 0, y: 0, opacity: 0 }}
            animate={controls[index]}
            className="absolute flex justify-center items-center"
          >
            <div
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              className={`border-[1px] ${
                isHovered === index && "z-20"
              } hover:text-white cursor-pointer hover:bg-[#538BFD] py-[20px] px-[60px]  relative border-[#D2D2D2] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] bg-[#FFFFFF] p-[15px] rounded-t-lg text-center`}
            >
              <div
                className={`text-[${
                  isHovered === index ? "#FFFFFF" : "#272727"
                }]  text-[24px]  font-bold `}
              >
                {item.heading}
              </div>
              <div
                className={`text-[${
                  isHovered === index ? "#FFFFFF" : "#7A7A7A"
                }] font-normal text-[18px]`}
              >
                {item.text}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PowerInside;
