import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cardBgIcon from "../assets/img/cardBgImg.png";
import cardBgIconMobile from "../assets/img/cardBgIconMobile.png";

import cardIcon from "../assets/svg/cardIcon.svg";

const TestimonialSlider = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const windowWidth = document.documentElement.clientWidth;
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1.6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    // rtl: true,
    customPaging: (i) => (
      <div
        className={`w-[40px] h-[7px] rounded-[40px] mt-[50px] flex gap-[5px] ${currentSlide === i ? "bg-blue-500" : "bg-gray-300"
          }`}
      ></div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "10px",
        }}
      >
        {dots}
      </div>
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} classNam="bgGradient">
        {testimonials?.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="relative flex flex-col sm:flex-row justify-between gap-[10px] md:gap-[5px] lg:gap-[10px] sm:items-center  py-[24px] px-[25px] md:px-[20px] border-2
                 rounded-[30px] border-[#E1E1E1] border-solid  mx-[15px] h-[480px] sm:h-[480px] md:h-[400px] 2xl:h-[450px] overflow-hidden"
              >
                {windowWidth > 600 ? (
                  <img
                    className="absolute z-[-1]  right-0 top-0 bottom-0 h-[100%] max-w-none "
                    src={cardBgIcon}
                    alt="Background"
                  />
                ) : (
                  <img
                    className="absolute z-[-1]  left-0 right-0 bottom-0 w-[100%]"
                    src={cardBgIconMobile}
                    alt="Background"
                  />
                )}

                <div
                  className={`sm:w-[70%]   ${windowWidth > 600 ? "h-[100%]" : ""
                    } `}
                >
                  <div>
                    <img src={cardIcon} alt="Icon" className="max-w-full  " />
                  </div>
                  <div className="text-[15px] md:text-[18px] lg:text-[20px] 2xl:text-[24px] text-[#272727] text-justify leading-[24px] md:leading-[32px] mt-[20px] md:mt-[10px] lg:mt-[20px] ">
                    {item.message}
                  </div>
                </div>
                <div className=" sm:w-[30%] md:w-[27vw] lg:w-[20vw] flex flex-col justify-center ">
                  <div className=" w-[12vw] overflow-hidden m-auto  ">
                    <img src={item.image} alt="Profile" className="w-full object-cover" />
                  </div>
                  <div className="text-[16px] md:text-[24px] font-semibold text-[#272727] text-center ">
                    {item.name}
                  </div>
                  <div className=" text-[12px] md:text-[15px] text-[#0F0F0F] mt-[6px] text-center  ">
                    {item.designation}
                  </div>
                  <div className=" flex justify-center ">
                    <img src={item.companyLogo} alt="Company Logo" className="h-[4vh] lg:h-[28px] max-w-full " />
                  </div>
                </div>
              </div >
            </>
          );
        })}
      </Slider>
    </div >
  );
};

export default TestimonialSlider;
