import { useState, useEffect } from 'react';


import BeautyOutside from './BeautyOutside';
import MobileProductSteps from './MobileBeautyOutside';

export default function ResponsiveProductSteps() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? <MobileProductSteps /> : <BeautyOutside />;
}