import React from "react";
import Hero from "../components/home/Hero";
import Projects from "../components/home/Projects";
import Info from "../components/home/Info";
import Expertise from "../components/home/Expertise";
import Globe from "../components/home/Globe";
import About from "../components/home/About";
import Testimonial from "../components/home/Testimonial";
import InsightsAndExpertise from "../components/home/InsightsAndExpertise";
import Contact from "../components/home/Contact";
import Info1 from "../components/home/Info1";
import Speedsection from "../components/home/Speedsection";
import DigitalExpectational from "../components/home/DigitalExpectational";
import Globe1 from "../components/home/Globe1";
import Stackingpageshome from "../components/home/Stackingpageshome";
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Es Magico | Quality AI Products, Design & Tech</title>
        <meta
          name="description"
          content="We build tech for early stage startups for GTM and help proven businesses scale. For intuitive UX, clean UI, robust apps, scalable tech, contact us."
        />
        <meta
          property="og:title"
          content="Es Magico | Quality AI Products, Design & Tech"
        />
        <meta
          property="og:description"
          content="We build tech for early stage startups for GTM and help proven businesses scale. For intuitive UX, clean UI, robust apps, scalable tech, contact us."
        />
      </Helmet>
      <div>
        {/* <SequentialAnimation /> */}
        <Hero />
        <Projects />
        <Expertise />
        <DigitalExpectational />
        <Speedsection />
        <Stackingpageshome />
        {/* <Info /> */}
        {/* <About /> */}
        {/* <Info1 /> */}
        {/* <InsightsAndExpertise />  */}
        <Globe1 />
        <Testimonial />
        <Info1 />
        <Contact />
      </div>
    </>

  );
};

export default Home;
