import React from "react";
import TopHeading from "../components/services/TopHeading";
import Efficiency from "../components/services/Efficiency";
import TechStack from "../components/services/TechStack";


import ResponsiveProductSteps from "../components/services/resposiveBeauty"
import ResponsivePowerSteps from "../components/services/responsivePower";
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Es Magico | Empowering Businesses with AI and Digital Solutions</title>
        <meta
          name="description"
          content="Partner with Es Magico for comprehensive digital transformation services. From AI and data solutions to enterprise systems scalability and design, we help businesses thrive in the digital age."
        />
        <meta
          property="og:title"
          content="Es Magico | Empowering Businesses with AI and Digital Solutions"
        />
        <meta
          property="og:description"
          content="Partner with Es Magico for comprehensive digital transformation services. From AI and data solutions to enterprise systems scalability and design, we help businesses thrive in the digital age."
        />
      </Helmet>
      <div >


        <TopHeading />

<div className="max-w-[1440px] m-auto">
<ResponsiveProductSteps />
        <ResponsivePowerSteps />
        <Efficiency />
        <TechStack />
</div>
        
      </div>
    </>

  );
};

export default Services;
