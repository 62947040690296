import React from 'react'
import shreyas from "../../assets/img/shreyas.png";
import shardul from "../../assets/img/shardul.png";
import rihika from "../../assets/img/rihika.png";
import akshat from "../../assets/img/akshat.png";
import lakshmi from "../../assets/img/lakshmi.png";
import akanksha from "../../assets/img/akanksha.png";
import mithali from "../../assets/img/mithali.png";
import akshay from "../../assets/img/akshay.png";
import abhishek from "../../assets/img/abhishek.png";
import aniket from "../../assets/img/aniket.png";
import kanhaiya from "../../assets/img/kanhaiya.png";
import jaanbaaz from "../../assets/img/jaanbaaz.png";
import ritesh from "../../assets/img/ritesh.png";
import faisal from "../../assets/img/faisal.png";
import raveena from "../../assets/img/raveena.png";
import { MemberCard } from './memberCard';

const teamMembers = [
  {
    img: shreyas,
    name: "Shreyas Sanghvi",
    post: "Co-Founder & CEO"
  },
  {
    img: shardul,
    name: "Shardul Singh",
    post: "Co-Founder & CTO"
  },
  {
    img: rihika,
    name: "Rihika Darak",
    post: "HR & Founder’s Manager"
  },
  {
    img: akshat,
    name: "Akshat Khamesra",
    post: "Product Manager"
  },
  {
    img: lakshmi,
    name: "Lakshmi Narayana",
    post: "Associate Product Manager"
  },
  {
    img: akanksha,
    name: "Akanksha Dasmohapatra",
    post: "Founder’s Office Associate"
  },
  {
    img: mithali,
    name: "Mithali Mewada",
    post: "Sr. Product Designer"
  },
  {
    img: akshay,
    name: "Akshay Anand",
    post: "Sr. Product Designer"
  },
  {
    img: abhishek,
    name: "Abhishek Kumar",
    post: "Full Stack Developer"
  },
  {
    img: aniket,
    name: "Aniket Dandagavhan",
    post: "Full Stack Engineer"
  },
  {
    img: kanhaiya,
    name: "Kanhaiya Singh",
    post: "Senior App Developer"
  },
  {
    img: jaanbaaz,
    name: "Jaanbaaz Akhtar",
    post: "Full Stack Engineer"
  },
  {
    img: ritesh,
    name: "Ritesh Goswami",
    post: "SDE 1"
  },
  {
    img: faisal,
    name: "Faisal Khan",
    post: "Full Stack Developer"
  },
  {
    img: raveena,
    name: "Raveena Kale",
    post: "Full Stack Engineer - QA, Tech"
  }
];


const Members = () => {
  return (

    <div className='flex gap-[12px] max-w-[340px] sm:max-w-[550px]  lg:max-w-[1220px] m-auto   lg:gap-[20px] flex-wrap pb-[36px]  lg:pb-[81px]'>
      {teamMembers.map((member, index) => {
        return < MemberCard key={index} img={member.img} name={member.name} post={member.post} />
      })}
    </div>


  )
}

export default Members