import React from 'react';
import UnifiedPreSales from '../../assets/img/UnifiedPreSales.png';
import PowerfulEsto from "../../assets/img/PowerfulEsto.png";
import DatadrivenMarketing from "../../assets/img/DatadrivenMarketing.png";
import RealTimeInventory from "../../assets/img/RealTimeInventory.png";
import StreamlinedAccounts from "../../assets/img/StreamlinedAccounts.png";

const FeaturesSectionEsto = () => {
    return (
        <>
            {/* Unified Pre-Sales & Sales Management Section  */}
            <div className=" my-8 overflow-x-hidden">
                <div className="flex flex-col md:flex-row  md:ml-[108px] md:mr-[108px] items-center justify-center gap-8  ">
                    <div className=" flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mt-[83.88px]  md:mb-[120px] gap-[8px] md:gap-[23px]">
                        <p className=" text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Unified Pre-Sales & Sales Management
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed ">
                            Track leads from first contact to closed deal. Automate workflows, schedule meetings, and manage follow-ups – all within a single platform.
                        </p>
                    </div>
                    <div className=" relative justify-center w-[243px] h-[150px] md:w-[540px] md:h-[303px]  rounded-lg overflow-hidden  md:mb-[65.13px] md:ml-[108px]  md:mt-[72px] pb-6  max-w-[540px] max-h-[303.75]">
                        <img src={UnifiedPreSales} alt="UnifiedPreSales" className=" object-cover" />
                    </div>
                </div>
            </div>

            {/* Powerful Post-Sales Management Section */}
            <div className=" my-8" >
                <div className="mt-40px   md:mr-[108px] items-center justify-center flex flex-col flex-col-reverse md:flex-row gap-8 ">
                    <div className=" relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[313px]  rounded-lg overflow-hidden  md:mb-[65.13px] md:ml-[108px]   pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={PowerfulEsto} alt="PowerfulEsto" className=" object-cover" />
                    </div>
                    <div className="flex flex-col  justify-center ml-[26px] mr-[26px] mt-40px md:mt-[40px]  md:mb-[147px] gap-[8px] md:ml-[108px] md:gap-[23px]">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Powerful Post-Sales Management
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Nurture customer relationships and ensure smooth sailing after the sale. Manage project stages, generate invoices, and guarantee customer satisfaction.
                        </p>
                    </div>
                </div>
            </div>

            {/* Data-Driven Marketing */}
            <div className="my-8">
                <div className="flex flex-col md:flex-row  mt-40px  md:ml-[108px] md:mr-[108px] items-center justify-center flex flex-col gap-8 ">
                    <div className=" flex flex-col justify-center ml-[26px] mr-[26px] mt-40px   md:mb-[120px] gap-[8px] md:gap-[23px]">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Data-Driven Marketing
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Craft targeted campaigns, manage vendors, and track performance in real-time. Measure ROI with ease and see what truly resonates with your audience.
                        </p>
                    </div>
                    <div className="relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[313px]  rounded-lg overflow-hidden  md:mb-[65.13px] md:ml-[108px]  pb-6  max-w-[540px] max-h-[312px]">
                        <img src={DatadrivenMarketing} alt="DatadrivenMarketing" className="  object-cover" />
                    </div>
                </div>
            </div>

            {/* Real - Time Inventory Visibility */}
            <div className="  my-8">
                <div className=" flex flex-col md:flex-row  flex-col-reverse mt-40px  md:mr-[108px] items-center justify-center flex flex-col gap-8  flex flex-col justify-center md:flex-row gap-8 ">
                    <div className="relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[313px]  rounded-lg overflow-hidden  md:mb-[65.13px] md:ml-[108px]   pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={RealTimeInventory} alt="RealTimeInventory " className="object-cover" />
                    </div>
                    <div className="flex flex-col justify-center item-center md:text-start md:items-start  md:ml-[108px] ml-[26px] mt-[40px] md:mt-[0px] md:mb-[120px] gap-[8px] md:gap-[23px] pr-10">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Real-Time Inventory Visibility
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Gain instant insights into your stock levels, perfect for any business managing inventory.
                        </p>
                    </div>
                </div>
            </div>

            {/* Streamlined Accounts & Finance */}
            <div className="my-8">
                <div className="flex flex-col md:flex-row  mt-40px  md:ml-[108px] md:mr-[108px] items-center justify-center flex flex-col gap-8 ">
                    <div className=" flex flex-col justify-center ml-[26px] mr-[26px] mt-40px  md:mb-[147px] gap-[8px] md:gap-[23px]">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Streamlined Accounts & Finance
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Simplify billing for your sales and marketing activities. Manage commissions for external partners with a secure approval workflow.
                        </p>
                    </div>
                    <div className="relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[313px]  rounded-lg overflow-hidden  md:mb-[65.13px] md:ml-[108px]   pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={StreamlinedAccounts} alt="StreamlinedAccounts" className="  object-cover" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturesSectionEsto;
