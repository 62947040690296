import React from 'react';
import Rocket from '../../assets/img/Rocket.png';
import { GoArrowRight } from 'react-icons/go';

const TransformSection = () => {
    return (
        <div className="w-full h-auto  flex items-center justify-center">
            <div className=" ml-[16px] md:ml-[108px]  flex rounded-[30px] md:mr-[108px] mr-[16px]  " style={{ backgroundColor: '#538CFF' }}>
                <div className="flex md:flex-row flex-col md:items-start items-center justify-center md:justify-start md:gap-[28px]">
                    <div className="mix-blend-luminosity w-[90px] h-[90px] md:ml-[48px] md:mt-[46.76px]  ">
                        <img src={Rocket} alt="Rocket" className="object-cover " />
                    </div>
                    <div className=" mr-[26px] ml-[26px] md:mr-[28px] gap-[32px] flex-col  items-center  mt-[46.76px]">
                        <p className=" text-center font-outfit text-[24px] md:text-[36px] md:text-start font-semibold leading-[25.2px] md:leading-[50.4px] text-[#FFFFFF]">
                            Ready to transform your sales & marketing game?
                        </p>
                        <button className=" ml-[10px] md:ml-[0px] bg-white flex items-center justify-center mt-[28px] mb-[55.83px] p-[12px_28px] rounded-[63px] border-[1px] ">
                            <p className="text-black text-center text-[18px] font-bold leading-[20px] font-dm-sans flex items-center">
                                Start your free trial
                                <span className="ml-2">
                                    <GoArrowRight />
                                </span>
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransformSection;
