import React from 'react';
import SettingsEsto from "../../assets/img/SettingsEsto.png";
import Estoedit from "../../assets/img/Estoedit.png";
import personsesto from "../../assets/img/personsesto.png";
import graph from "../../assets/img/graph.png";

const Estosection = () => {
    return (
        <div className="w-full mx-auto ">
            <div className="flex flex-col mx-4 justify-start md:flex-row md:ml-[102px] md:mr-[102px] mb-[44px] md:mt-[55px]  mx-auto rounded-[12px] md:rounded-[39px] bg-[#F9F9F9] border-3 border-white">
                <div className="flex flex-col h-auto p-4 gap-[22px] md:ml-[50px]">
                    <p className="text-[24px] mt-[20px] md:text-[40px] md:text-start text-center font-outfit font-bold leading-[63px] text-[#0F0F0F]">
                        Why Choose ESTO?
                    </p>
                    <div className="flex flex-col gap-[30px]  md:w-[70%]">
                        <div className="flex items-start  ">
                            <div className=" mr-[0px] md:mr-[23.5px]">
                                <img
                                    src={SettingsEsto}
                                    alt="SettingsEsto"
                                    className=" object-cover rounded-lg md:w-[50px] md:h-[40px] w-[60px] "
                                />
                            </div>
                            <div className="flex flex-col pl-[10px] mx-2 ">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Unmatched Functionality
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Manage every stage of the customer journey – from pre-sales to post-sales and beyond.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start  ">
                            <div className=" md:mr-[23.5px]">
                                <img
                                    src={Estoedit}
                                    alt="Estoedit"
                                    className=" object-cover rounded-lg md:w-[40px] md:h-[40px] w-[60px]"
                                />
                            </div>
                            <div className="flex flex-col pl-[10px] ">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Effortlessly Customizable
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Tailor ESTO to fit your specific business processes for a truly integrated solution.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start  ">
                            <div className=" md:mr-[23.5px]">
                                <img
                                    src={personsesto}
                                    alt="personsesto"
                                    className=" object-cover rounded-lg md:w-[40px] md:h-[40px] w-[60px]"
                                />
                            </div>
                            <div className="flex flex-col pl-[10px]">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Enhanced Collaboration
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Break down silos and empower seamless communication between teams.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start ">
                            <div className=" md:mr-[23.5px] ">
                                <img
                                    src={graph}
                                    alt="graph"
                                    className=" object-cover rounded-lg md:w-[50px] md:h-[40px] w-[80px]"
                                />
                            </div>
                            <div className="flex flex-col pl-[10px] ">
                                <p className="md:text-[28px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Actionable Insights
                                </p>
                                <p className="md:text-[22px] text-[14px] font-outfit font-normal leading-[27.72px] text-[#7A7A7A]">
                                    Gain valuable data to optimize your campaigns, maximize ROI, and make data-driven decisions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="relative flex justify-center items-center md:mt-[131px] ml-[75px] md:mr-[62px] md:ml-[10px] mr-[75px]">
                    <img
                        src={arrows}
                        alt="arrows"
                        className="object-cover mix-blend-luminosity  "
                    />
                </div> */}
            </div>
        </div>
    );
};

export default Estosection;
