import React from "react";
import TopHeading from "../components/products/TopHeading";
import SoftwareAsService from "../components/home/SoftwareAsService";
import { Helmet } from 'react-helmet';

const Products = () => {
  return (
    <>
      <Helmet>
        <title>Explore Es Magico's AI-Powered Solutions | Gratify, Esto, Pyzo</title>
        <meta
          name="description"
          content="Discover our innovative SaaS products designed to transform your business. From customer engagement to marketing and sales optimization, Es Magico offers tailored solutions to meet your needs."
        />
        <meta
          property="og:title"
          content="Explore Es Magico's AI-Powered Solutions | Gratify, Esto, Pyzo"
        />
        <meta
          property="og:description"
          content="Discover our innovative SaaS products designed to transform your business. From customer engagement to marketing and sales optimization, Es Magico offers tailored solutions to meet your needs."
        />
      </Helmet>

      <div>
        <TopHeading />
        <div className="w-[90%] m-auto">
          <SoftwareAsService />
        </div>
      </div>
    </>

  );
};

export default Products;
