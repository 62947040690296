// import React, { useState, useEffect } from "react";
// import { Link, NavLink } from "react-router-dom";
// import logo from "../assets/esmagico.png";
// import buttonArrow from "../assets/Map Arrow Up.png";
// import { motion } from "framer-motion";
// import menu from "../assets/svg/span.svg";
// import { FaLocationArrow } from "react-icons/fa";

// export const Navbar = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
//   const [visible, setVisible] = useState(true);
//   const [showBackground, setShowBackground] = useState(false);
//   const [textColor, setTextColor] = useState(false);
//   const windowWidth = document.documentElement.clientWidth;

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [prevScrollPos]);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowBackground(true);
//     }, 5000);
//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     const seconds = windowWidth <= 1750 ? 3500 : 3800;
//     const timer = setTimeout(() => {
//       setTextColor(true);
//     }, seconds);
//     return () => clearTimeout(timer);
//   }, []);

//   const handleScroll = () => {
//     const currentScrollPos = window.pageYOffset;
//     setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
//     setPrevScrollPos(currentScrollPos);
//   };

//   const handleClose = () => {
//     setMenuOpen(false);
//   };

//   return (
//     <motion.nav
//       initial={{ y: -100 }}
//       animate={{ y: 0, color: "black" }}
//       transition={{ delay: 1, duration: 0.4 }}
//       className={`${visible ? "visible" : "hidden"} z-50`}
//       style={{ backgroundColor: showBackground ? " #FFFFFF" : "" }}
//     >
//       {/* Title */}
//       <Link to="/" onClick={handleClose}>
//         <img src={logo} alt="" />
//       </Link>

//       {/* Hamburger Menu */}
//       <div
//         className="menu"
//         style={{ cursor: "pointer" }}
//         onClick={() => setMenuOpen((prev) => !prev)}
//       >
//         <img src={menu} alt="" />
//       </div>

//       {/* All Links */}
//       <motion.ul
//         initial={{ color: "white" }}
//         animate={{ color: "black" }}
//         transition={{ delay: 1.6, duration: 0.5 }}
//         className={menuOpen ? "open" : ""}
//         style={{
//           backgroundColor: showBackground ? " #FFFFFF" : "",
//         }}
//       >
//         <li>
//           <NavLink onClick={handleClose} to="/about">
//             About
//           </NavLink>
//         </li>
//         <li>
//           <NavLink onClick={handleClose} to="/services">
//             Services
//           </NavLink>
//         </li>
//         <li>
//           <NavLink onClick={handleClose} to="/products">
//             Products
//           </NavLink>
//         </li>
//         <li>
//           <NavLink onClick={handleClose} to="/case-studies">
//             Case Studies
//           </NavLink>
//         </li>
//         <li>
//           <NavLink onClick={handleClose} to="/contact">
//             Contact
//           </NavLink>
//         </li>
//         <li
//           className={`bg-black ${textColor ? "text-white" : "text-black"
//             } rounded-3xl flex items-center justify-center py-[5px]  px-4`}
//         >
//           {/* <button className="font-semibold" onClick={handleClose}>
//             Start Building{" "}
//           </button> */}
//           <button
//             className="font-semibold "
//             onClick={() => window.open("http://wa.me/919468644446", "_blank")}
//           >
//             Start Building
//           </button>
//           {" "}
//           <FaLocationArrow size={22} color={textColor ? "#FFFFFF" : "#00000"} />
//         </li>
//       </motion.ul>
//     </motion.nav>
//   );
// };


import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import EsMagico1 from "../assets/EsMagico1.png";
import { motion } from "framer-motion";
import menu from "../assets/svg/span.svg";
import { FaLocationArrow } from "react-icons/fa";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [showBackground, setShowBackground] = useState(false);
  const [textColor, setTextColor] = useState(false);
  const { pathname } = useLocation();
  const topRef = useRef(null); // Define the ref

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBackground(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTextColor(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const screenWidth = window.innerWidth;
  const getWidth = () => {
    if (screenWidth <= 370) {
      return '35vw'; // Width for very small screens
    } else if (screenWidth <= 480) {
      return '30vw'; // Width for small screens
    } else if (screenWidth <= 768) {
      return '20vw'; // Width for tablets
    } else if (screenWidth >= 1024) {
      return '13vw'; // Width for larger screens
    } else {
      return '20vw'; // Default width
    }
  };

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0, color: "black" }}
      transition={{ delay: 1, duration: 0.4 }}
      className={`${visible ? "visible" : "hidden"} z-100 md:flex-row md:gap-[50px] lg:gap-[0px]`}
      style={{ backgroundColor: showBackground ? "#FFFFFF" : "" }}
    >
      {/* Title */}
      <Link to="/" onClick={handleClose}>
        <img
          src={EsMagico1}
          alt="Logo"
          style={{ width: getWidth() }}
        />
      </Link>
      {/* Hamburger Menu */}
      <div
        className="md:hidden cursor-pointer absolute top-5 right-5"
        onClick={() => setMenuOpen((prev) => !prev)}
      >
        <img src={menu} alt="Menu" />
      </div>

      {/* Overlay */}
      {menuOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-50 z-30"></div>
      )}

      {/* Full-Screen Menu for Mobile */}
      <motion.div
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? '0%' : '-100%' }}
        className={`fixed top-0 left-0 w-full h-screen bg-white z-40 flex flex-col items-center justify-center  md:hidden`}
        style={{ backgroundColor: showBackground ? "#FFFFFF" : "" }}
      >
        <button className="absolute top-5 right-5 text-5xl" onClick={handleClose}>
          &times;
        </button>
        <ul className="flex flex-col items-center space-y-8 text-[40px]">
          <li>
            <NavLink className="text-[40px]" onClick={handleClose} to="/services">
              Services
            </NavLink>
          </li>
          <li>
            <NavLink className="text-[40px]" onClick={handleClose} to="/products">
              Products
            </NavLink>
          </li>
          <li>
            <NavLink className="text-[40px]" onClick={handleClose} to="/Work">
              Work
            </NavLink>
          </li>
          <li>
            <NavLink className="text-[40px]" onClick={handleClose} to="/Teams">
              Teams
            </NavLink>
          </li>
        </ul>
      </motion.div>

      {/* Menu for Larger Screens */}
      <div className="hidden md:flex items-center md:space-x-2 md:text-base  lg:space-x-8 xl:space-x-10 xl:text-xl 2xl:space-x-6 2xl:text-2xl sm:text-xl text-[38px]">
        <NavLink onClick={handleClose} to="/services">
          Services
        </NavLink>
        <NavLink onClick={handleClose} to="/products">
          Products
        </NavLink>
        <NavLink onClick={handleClose} to="/Work">
          Work
        </NavLink>
        <NavLink onClick={handleClose} to="/Teams">
          Teams
        </NavLink>
        <li
          className={`bg-black ${textColor ? "text-white" : "text-black"} rounded-3xl flex p-6 items-center gap-[5px] p-2 justify-center py-[5px] px-8`}
        >
          <button
            className="font-15px"
            onClick={() => window.open("http://wa.me/919468644446", "_blank")}
          >
            Book a Call
          </button>
          <FaLocationArrow size={20} color={textColor ? "#FFFFFF" : "#000000"} style={{ marginLeft: '10px' }} />
        </li>
      </div>
    </motion.nav>
  );
};
