import React from 'react'
import goa1 from "../../assets/img/goa1.png";
import goa2 from "../../assets/img/goa2.png";
import goa3 from "../../assets/img/goa3.png";
import goa4 from "../../assets/img/goa4.png";
import goa5 from "../../assets/img/goa5.png";

const GoaTour = () => {
  return (
    <div className="flex pt-[32px] lg:pt-[100px] gap-[12px]  bg-white font-['Outfit'] lg:gap-[20px] lg:pb-[91px] pb-[47px] flex-wrap max-w-[340px] sm:max-w-[550px]  lg:max-w-[1220px] m-auto ">
        <div className=' w-[328px] lg:w-[540px] lg:mr-[80px]'> <p className='font-bold text-[40px]'>
        Es Magico In Goa
        </p>
        <p className='text-[##7A7A7A] text-[24px]'>
        Building Connections, Creating Memories. Our Team in Action at Es Magico Tech Studio's Annual Outing
        </p>
        </div>
    <img src={goa1} alt="" className='h-[206px] w-[328px] lg:h-[353px] lg:w-[550px]' />
    <img src={goa2} alt="" className='h-[203px] w-[328px] lg:h-[501px] lg:w-[793px]' />
    <img src={goa3} alt="" className='h-[207px] w-[160px] lg:h-[501px] lg:w-[387px]' />
    <img src={goa4} alt="" className='h-[207px] w-[160px] lg:h-[501px] lg:w-[387px]' />
    <img src={goa5} alt="" className='h-[203px] w-[328px] lg:h-[501px] lg:w-[793px]' />
    </div>
  )
}

export default GoaTour