import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const windowWidth = document.documentElement.clientWidth;

const BeautyOutside = () => {
  const refs = [
    {
      ref: useRef(null),
      heading: "User Search",
      text: "We uncover user needs",
    },
    {
      ref: useRef(null),
      heading: "Mind Mapping",
      text: "We map out you ideas visually",
    },
    {
      ref: useRef(null),
      heading: "User Experience Wireframing",
      text: "We plan user journeys for smooth experiences",
    },
    {
      ref: useRef(null),
      heading: "UI Design",
      text: "We design user-friendly interfaces",
    },
    {
      ref: useRef(null),
      heading: "Intraction Design",
      text: "We make you product interactive",
    },
    {
      ref: useRef(null),
      heading: "Responsive Adaptations",
      text: "We ensure you product looks great on all devices",
    },
    {
      ref: useRef(null),
      heading: "User Testing and Proctoring ",
      text: "We test your product with real users and get feedback",
    },
  ];

  const controls = [
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
  ];

  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls[0].start({
            x:
              windowWidth <= 1030
                ? "-15vw"
                : windowWidth <= 1280
                  ? "-13vw"
                  : windowWidth <= 1370
                    ? "-12vw"
                    : windowWidth <= 1445
                      ? "-11vw"
                      : "-11vw",
            y: windowWidth <= 1030 ? "-13vh" : "-13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[1].start({
            x:
              windowWidth <= 1030
                ? "19vw"
                : windowWidth <= 1280
                  ? "15vw"
                  : windowWidth <= 1370
                    ? "14vw"
                    : windowWidth <= 1445
                      ? "13vw"
                      : "12vw",
            y: windowWidth <= 1030 ? "-13vh" : "-13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[2].start({
            x:
              windowWidth <= 1030
                ? "-22vw"
                : windowWidth <= 1280
                  ? "-18vw"
                  : windowWidth <= 1370
                    ? "-17vw"
                    : windowWidth <= 1445
                      ? "-16vw"
                      : "-14vw",
            y: windowWidth <= 1030 ? "0vh" : "0vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[3].start({
            x:
              windowWidth <= 1030
                ? "22vw"
                : windowWidth <= 1280
                  ? "18vw"
                  : windowWidth <= 1370
                    ? "17vw"
                    : windowWidth <= 1445
                      ? "16vw"
                      : "16vw",
            y: windowWidth <= 1030 ? "0vh" : "0vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[4].start({
            x:
              windowWidth <= 1030
                ? "-22vw"
                : windowWidth <= 1280
                  ? "-18vw"
                  : windowWidth <= 1370
                    ? "-16vw"
                    : windowWidth <= 1445
                      ? "-15vw"
                      : "-15vw",
            y: windowWidth <= 1030 ? "13vh" : "13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[5].start({
            x:
              windowWidth <= 1030
                ? "23vw"
                : windowWidth <= 1280
                  ? "19vw"
                  : windowWidth <= 1370
                    ? "18vw"
                    : windowWidth <= 1445
                      ? "17vw"
                      : "15vw",
            y: windowWidth <= 1280 ? "13vh" : "13vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          controls[6].start({
            x: "0vw",
            y: "25vh",
            opacity: 1,
            transition: { duration: 1 },
          });
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    refs.forEach((item) => {
      if (item.ref.current) {
        observer.observe(item.ref.current);
      }
    });

    return () => {
      refs.forEach((item) => {
        if (item.ref.current) {
          observer.unobserve(item.ref.current);
        }
      });
    };
  }, [controls, refs]);

  return (
    <div className=" px-5 pt-[60px] lg:pb-[110px] w-[90%] m-auto relative">
      <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center">
        We built Products that are beautiful on the
      </h1>
      <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center">
        outside...
      </h1>
      <div className="relative w-full h-[55vh] flex justify-center items-center">
        {refs.map((item, index) => (
          <motion.div
            key={index}
            ref={item.ref}
            initial={{ x: 0, y: 0, opacity: 0 }}
            animate={controls[index]}
            className="absolute flex justify-center items-center"
          >
            <div
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              className={`border-[1px] ${isHovered === index && "z-20"
                } hover:text-white cursor-pointer hover:bg-[#538BFD] relative border-[#D2D2D2] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] bg-[#FFFFFF] py-[20px] px-[60px] rounded-t-lg text-center`}
            >
              <div
                className={`text-[${isHovered === index ? "#FFFFFF" : "#272727"
                  }]  text-[24px]  font-bold `}
              >
                {item.heading}
              </div>
              <div
                className={`text-[${isHovered === index ? "#FFFFFF" : "#7A7A7A"
                  }] font-normal text-[18px]`}
              >
                {item.text}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default BeautyOutside;
