import React, { useEffect, useRef } from 'react';
import StackEsto5 from "../../assets/img/StackEsto5.png";
import StackEsto4 from "../../assets/img/StackEsto4.png";
import StackEsto3 from "../../assets/img/StackEsto3.png";
import StackEsto2 from "../../assets/img/StackEsto2.png";
import StackEsto1 from "../../assets/img/StackEsto1.png";
import Rectangle from "../../assets/img/Rectangle.png";


// const Card = ({ title, description1, description2, description3, description4, imageUrl, index }) => {
//     return (
//         <div className="sticky flex items-center justify-center mx-[25px]" style={{ top: `${(index + 1) * 10}px`, opacity: 1 }}>
//             <div className="p-5 shadow-[0_0_30px_0_rgba(0,0,0,0.3)] rounded-[24px] transition-all duration-300 text-white origin-top">
//                 <div className="flex flex-col md:flex-row sticky items-center md:gap-[46px] w-[100%]" style={{ top: `${(index + 1) * 10}px` }}>
//                     <div className="w-[90%] md:w-[60%] shadow-[0_0_30px_0_rgba(0,0,0,0.3)] text-center md:text-start transition-all duration-300 text-white origin-top">
//                         <h1 className="font-['Outfit'] text-[17px] md:text-[41px] leading-[19px] md:leading-[55px] mb-4">{title}</h1>

//                         {description1 && (
//                             <div className="flex items-center justify-center md:justify-start mb-4">
//                                 <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
//                                 <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
//                                     {description1}
//                                 </p>
//                             </div>
//                         )}

//                         {description2 && (
//                             <div className="flex items-center justify-center md:justify-start mb-4">
//                                 <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
//                                 <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
//                                     {description2}
//                                 </p>
//                             </div>
//                         )}

//                         {description3 && (
//                             <div className="flex items-center justify-center md:justify-start mb-4">
//                                 <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
//                                 <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
//                                     {description3}
//                                 </p>
//                             </div>
//                         )}

//                         {description4 && (
//                             <div className="flex items-center justify-center md:justify-start mb-4">
//                                 <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
//                                 <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
//                                     {description4}
//                                 </p>
//                             </div>
//                         )}


//                     </div>

//                     <div className="relative flex md:flex-row" style={{ width: '45%', height: 'auto' }}>
//                         <img src={imageUrl} alt={title} className="object-cover" />
//                     </div>
//                 </div>
//             </div>
//         </div>

//     );
// };

const Card = ({ title, subtitle, description1, description2, description3, description4, imageUrl, index }) => {
    return (
        <div className="sticky flex items-center justify-center mx-[25px]" style={{ top: `${(index + 1) * 10}px`, opacity: 1 }}>
            <div className="p-5 shadow-[0_0_30px_0_rgba(0,0,0,0.3)] rounded-[24px] transition-all duration-300 text-white origin-top">
                <div className="flex flex-col md:flex-row sticky items-center md:gap-[46px] w-[100%]" style={{ top: `${(index + 1) * 10}px` }}>
                    <div className="w-[90%] md:w-[60%] shadow-[0_0_30px_0_rgba(0,0,0,0.3)] text-center md:text-start transition-all duration-300 text-white origin-top">

                        {/* Title */}
                        <h1 className="xl:w-[600px] 2xl:w-[600px] md:ml-[50px] font-['Outfit'] text-[17px] md:text-[41px] leading-[19px] md:leading-[55px] mb-2">{title}</h1>

                        {/* Subtitle */}
                        {subtitle && (
                            <h2 className="font-['Outfit'] md:ml-[50px] text-[14px] md:text-[30px] leading-[17px] md:leading-[40px] mb-4">{subtitle}</h2>
                        )}

                        {/* Descriptions */}
                        {description1 && (
                            <div className="flex items-center md:ml-[50px] justify-center md:justify-start mb-4">
                                <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
                                <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
                                    {description1}
                                </p>
                            </div>
                        )}

                        {description2 && (
                            <div className="flex items-center md:ml-[50px] justify-center md:justify-start mb-4">
                                <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
                                <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
                                    {description2}
                                </p>
                            </div>
                        )}

                        {description3 && (
                            <div className="flex items-center md:ml-[50px] justify-center md:justify-start mb-4">
                                <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
                                <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
                                    {description3}
                                </p>
                            </div>
                        )}

                        {description4 && (
                            <div className="flex items-center md:ml-[50px] justify-center md:justify-start mb-4">
                                <img src={Rectangle} alt="Rectangle" className="mr-2 w-[15px] md:w-[20px] object-cover" />
                                <p className="font-['Outfit'] text-[14px] md:text-2xl font-normal font-semibold leading-[30.24px]">
                                    {description4}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="relative flex md:flex-row" style={{ width: '45%', height: 'auto' }}>
                        <img src={imageUrl} alt={title} className="object-cover" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardsContainer = ({ cards }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const cards = containerRef.current.querySelectorAll('.sticky');
            const data = {
                Location: {},
                ToReduceBy: {},
            };

            cards.forEach((card, index) => {
                const stickyTop = parseInt(window.getComputedStyle(card).top);
                const currentTop = card.getBoundingClientRect().top;
                data.Location[index] = currentTop - stickyTop;
                data.ToReduceBy[index] = 0;
            });

            for (let i = 0; i < cards.length; i++) {
                if (data.Location[i] <= 20) {
                    for (let j = i; j > -1; j--) {
                        data.ToReduceBy[j]++;
                    }
                }
            }

            cards.forEach((card, index) => {
                const innerCard = card.firstElementChild;
                const backgroundColorAlpha = 1 - (data.ToReduceBy[index] - 1) * 0.05;
                innerCard.style.transform = `scale(${1 - (data.ToReduceBy[index] * 0.05)})`;
                innerCard.style.backgroundColor = `rgba(0,0,0,${backgroundColorAlpha})`;
            }
            );
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <div className="flex flex-col items-center justify-center " ref={containerRef}>
            {cards.map((card, index) => (
                <Card key={index} {...card} index={index} />
            ))}
        </div>
    );
};

const CardEsto = () => {

    const cardsData = [
        {
            title: "Pre-Sales & Sales Management",
            imageUrl: Rectangle,
            description1: "Effortless Lead Tracking",
            imageUrl: Rectangle,
            description2: "Automated Workflows",
            imageUrl: Rectangle,
            description3: "Streamlined Communication",
            imageUrl: Rectangle,
            description4: "Unified Sales Pipeline",
            imageUrl: StackEsto5,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Inventory Management (coming soon)  ",
            //description1: "(coming soon)",
            imageUrl: Rectangle,
            description1: "Real-Time Inventory Visibility",
            imageUrl: Rectangle,
            description2: "Stock Control",
            imageUrl: StackEsto4,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Post-Sales Management",
            imageUrl: Rectangle,
            description1: "Customer Relationship Management",
            imageUrl: Rectangle,
            description2: "Commission Management",
            imageUrl: Rectangle,
            description3: "Accounting Integrations",
            imageUrl: StackEsto3,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Accounts & Finance Management  (coming soon)  ",
            //description1: "(coming soon)",
            imageUrl: Rectangle,
            description1: "Simplified Billing",
            imageUrl: Rectangle,
            description2: "Commission Management",
            imageUrl: Rectangle,
            description3: "Accounting Integrations",
            imageUrl: StackEsto2,
            backgroundColor: "rgba(0,0,0)",
        },
        {
            title: "Marketing Automation ",
            imageUrl: Rectangle,
            description1: "Targeted Campaigns",
            imageUrl: Rectangle,
            description2: "Vendor Management",
            imageUrl: Rectangle,
            description3: "Real-Time Performance Tracking",
            imageUrl: Rectangle,
            description4: "Lead Attribution & ROI Measurement",
            imageUrl: StackEsto1,
            backgroundColor: "rgba(0,0,0)",
        }
    ];

    return (
        <div className="w-full h-screen flex items-center justify-center  mx-auto mt-[500px] md:mt-[600px]  ">
            <CardsContainer cards={cardsData} />
        </div>
    );
};

export default CardEsto;