import React from 'react';
import RollBased from "../../assets/img/RollBased.png";
import SeamlessIntegration from "../../assets/img/SeamlessIntegration.png";
import arrow from "../../assets/img/arrow.png";
import ActionableInsight from "../../assets/img/ActionableInsight.png";

const BuildForCollab = () => {
    return (
        <div className="w-full mx-auto ">
            <div className="flex flex-col justify-center  md:ml-[102px] md:mr-[102px] mb-[44px]  mx-auto rounded-[39px]">
                <div className="flex flex-col h-auto p-6 gap-[42px]">
                    <p className="text-[24px] md:text-[50px] md:text-start text-center font-outfit font-bold leading-[30.24px] md:leading-[63px] text-[#0F0F0F]">
                        Built for Collaboration & Customization
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[60px]">
                        <div className="flex flex-col justify-center items-center md:justify-start md:items-start md:mt-[42px]">
                            <div className="mb-4 md:mb-0">
                                <img
                                    src={RollBased}
                                    alt="RollBased"
                                    className="object-cover rounded-lg w-[30px] h-[30px] md:w-[49px] md:h-[49px]"
                                />
                            </div>
                            <div className="flex  flex-col justify-center items-center md:justify-start md:items-start md:mt-[5px]">
                                <p className="md:text-[26px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Role-Based Access Control
                                </p>
                                <p className=" md:text-[20px]  text-[14px] font-outfit font-normal leading-[18.83px] md:leading-[27.72px] text-[#7A7A7A] text-center md:text-left">
                                    Ensure data security and team efficiency with granular permissions.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center items-center md:justify-start md:items-start md:mt-[42px]">
                            <div className="mb-4 md:mb-0">
                                <img
                                    src={SeamlessIntegration}
                                    alt="SeamlessIntegration"
                                    className="object-cover rounded-lg w-[30px] h-[30px] md:w-[49px] md:h-[49px]"
                                />
                            </div>
                            <div className="flex  flex-col justify-center items-center md:justify-start md:items-start md:mt-[5px]">
                                <p className="md:text-[26px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Seamless Integration
                                </p>
                                <p className=" md:text-[20px]  text-[14px] font-outfit font-normal leading-[18.83px] md:leading-[27.72px] text-[#7A7A7A] text-center md:text-left">
                                    Connect ESTO with your favorite tools for email marketing, calling, payments, and more. No data silos here!
                                </p>
                            </div>
                        </div>


                        <div className="flex flex-col justify-center items-center md:justify-start md:items-start ">
                            <div className="mb-4 md:mb-0">
                                <img
                                    src={ActionableInsight}
                                    alt="ActionableInsight"
                                    className="object-cover rounded-lg w-[30px] h-[30px] md:w-[49px] md:h-[49px]"
                                />
                            </div>
                            <div className="flex  flex-col justify-center items-center md:justify-start md:items-start md:mt-[5px]">
                                <p className="md:text-[26px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Actionable Insights
                                </p>
                                <p className=" md:text-[20px]  text-[14px] font-outfit font-normal leading-[18.83px] md:leading-[27.72px] text-[#7A7A7A] text-center md:text-left">
                                    Gain valuable data to optimize your sales & marketing strategies. Make informed decisions backed by hard numbers.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center items-center md:justify-start md:items-start ">
                            <div className="mb-4 md:mb-0">
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className="object-cover rounded-lg w-[30px] h-[30px] md:w-[49px] md:h-[49px]"
                                />
                            </div>
                            <div className="flex  flex-col justify-center items-center md:justify-start md:items-start md:mt-[5px]">
                                <p className="md:text-[26px] text-[18px] font-outfit font-semibold leading-[38.33px] text-[#0F0F0F]">
                                    Flexible & Scalable
                                </p>
                                <p className=" md:text-[20px] text-[14px] font-outfit font-normal leading-[18.83px] md:leading-[27.72px] text-[#7A7A7A] text-center md:text-left">
                                    ESTO adapts to your unique needs, no matter your industry or company size.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildForCollab;
