// // import React, { useState } from 'react';
// // import RBIback from "../../assets/img/RBI Back.png";
// // import isbimage from "../../assets/img/isbimage.png";
// // import Krushai from "../../assets/img/Krushai.png";
// // import Samagra from "../../assets/img/Samagra3.png";

// // const ExpertiseSection = () => {
// //   const cardDataArray = [
// //     {
// //       image: RBIback,
// //       text: (
// //         <>
// //           <span className="font-bold">RBI:</span>
// //           <br />
// //           <span className="mt-4 font-medium">
// //             An AI-driven chatbot designed to efficiently resolve customer grievances for the Reserve Bank of India. Leveraging advanced natural language processing, the chatbot accurately identifies and categorizes customer complaints, providing real-time resolutions or directing inquiries to appropriate departments.
// //           </span>
// //         </>
// //       ),
// //       mobileText: "More About RBI" // Mobile text overlay
// //     },
// //     {
// //       image: isbimage,
// //       text: (
// //         <>
// //           <span className="font-bold">ISB:</span>
// //           <br />
// //           <span className="mt-4 font-medium">
// //             An LLM based interview bot that scrapes the user profile and uses GPT-4 to conduct a personalized interview. The candidate profile is fed to the fine-tuned GPT model which conducts an interview and scores the candidate on the basis of a set of pre-defined personality traits.
// //           </span>
// //         </>
// //       ),
// //       mobileText: "More About ISB"
// //     },
// //     {
// //       image: Krushai,
// //       text: (
// //         <>
// //           <span className="font-bold">KrushAI - Bot for Farmers :</span>
// //           <br />
// //           <span className="mt-4 font-medium ">
// //             KrushAI is an intelligently powered agricultural assistant that provides farmers with real-time information and guidance in their native language. By leveraging advanced natural language processing, KrushAI accurately answers queries about government schemes, crop cycles, and best practices. This multilingual chatbot is designed to empower farmers with the knowledge they need to optimize their yields and livelihoods.
// //           </span>
// //         </>
// //       ),
// //       mobileText: "More About KrushAI"
// //     },
// //     {
// //       image: Samagra,
// //       text: (
// //         <>
// //           <span className="font-bold">Samagra:</span>
// //           <br />
// //           <span className="mt-4 font-medium ">
// //             LLM-based multilingual chatbots trained on custom datasets that help the end user obtain answers to their queries in a simple conversational manner.
// //           </span>
// //         </>
// //       ),
// //       mobileText: "More About Samagra"
// //     }
// //   ];

// //   const HoverCard = ({ image, text, mobileText }) => {
// //     const [isMobileTextVisible, setIsMobileTextVisible] = useState(false);

// //     const handleMobileTextClick = () => {
// //       setIsMobileTextVisible(true);

// //       // Set a timeout to hide the text after a certain duration (e.g., 3 seconds)
// //       setTimeout(() => {
// //         setIsMobileTextVisible(false);
// //       }, 3000); // 3000ms = 3 seconds
// //     };

// //     return (
// //       <div className="relative overflow-hidden rounded-lg shadow-xl group">
// //         <img
// //           src={image}
// //           alt="Card Image"
// //           className="object-cover transition-transform duration-300 ease-in-out"
// //         />
// //         {/* Desktop text - shown on hover for larger screens */}
// //         <div
// //           className={`absolute inset-0 flex items-top justify-center bg-white text-black opacity-0 md:group-hover:opacity-100 transition-all duration-300 ease-in-out p-4 ${isMobileTextVisible ? 'md:opacity-0' : ''}`}
// //         >
// //           <p className="text-start font-bold text-sm md:text-base leading-relaxed">{text}</p>
// //         </div>
// //         {/* Mobile overlay text - shown when clicked on mobile */}
// //         <div
// //           className="absolute  flex items-center justify-center text-white underline p-4 md:hidden cursor-pointer"
// //           onClick={handleMobileTextClick}
// //         >
// //           <p className="text-lg font-bold">{mobileText}</p>
// //         </div>
// //         {/* Full text for mobile, toggled by click */}
// //         {isMobileTextVisible && (
// //           <div className="absolute inset-0 flex items-center justify-center bg-white text-black transition-all duration-300 ease-in-out p-4 md:hidden">
// //             <p className="text-start font-bold text-sm md:text-medium leading-relaxed">{text}</p>
// //           </div>
// //         )}
// //       </div>
// //     );
// //   };

// //   return (
// //     <div className="w-full  mx-auto"> {/* Added h-screen and overflow-y-auto for scrollable content */}
// //       {/* H2 Section */}
// //       <h2 className="text-Outfit text-[20px]  md:text-[38px] font-semibold mt-[20px] md:mt-[65px] leading-[25.2px] md:leading-[60px] text-[#0F0F0F] lg:text-5xl text-center">
// //         We Trained LLM Models & Building Generative AI Powered Solutions for
// //       </h2>

// //       {/* Card Grid Section */}
// //       <div className="grid grid-cols-1  md:grid-cols-2 gap-[20px] md:gap-[40px] px-[16px] md:px-[60px] mt-[20px] md:mt-[70px]"> {/* Adjusted padding and margin for small screens */}
// //         {cardDataArray.map((card, index) => (
// //           <HoverCard key={index} image={card.image} text={card.text} mobileText={card.mobileText} />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default ExpertiseSection;

import React, { useState } from 'react';
import RBIback from "../../assets/img/RBI.png";
import isbimage from "../../assets/img/isbimage.png";
import Krushai from "../../assets/img/Krushai.png";
import Samagra from "../../assets/img/Samagra3.png";

const ExpertiseSection = () => {
  const cardDataArray = [
    {
      image: RBIback,
      text: (
        <>
          <div className='mb-2' ><span className="font-bold 2xl:text-3xl mb-5">RBI - Innovation Hub's AI-Driven Grievance Resolution:</span></div>
          <span className="font-normal text-base sm:text-xs xl:text-lg lg:text-base md:text-xs 2xl:text-2xl ">
            Our RBI Grievance Resolution application is an AI driven solution that efficiently handles customer complaints using NLP and RAG technology. We accurately categorize issues, deliver real-time responses, and seamlessly escalate complex cases to the relevant RBI departments, ensuring swift resolutions and enhanced service quality. In this project we did on-prem deployments for multiple banks.
          </span>
        </>
      ),
      mobileText: "More About RBI"
    },
    {
      image: isbimage,
      text: (
        <>
        <div className='mb-2' ><span className="font-bold text-base 2xl:text-3xl">ISB Gen-AI Interviewer:</span></div>
          <span className="font-normal  text-base sm:text-xs lg:text-base md:text-xs xl:text-lg 2xl:text-2xl">
          Our Gen-AI based interview platform that conducts interviews and shortlists candidates based on their profiles. We optimised LLM models to craft highly personalized interviews, score and evaluate responses based on predefined criterias and personality traits. We had an accuracy of 96% as verfied by ISB professors.
          </span>
        </>
      ),
      mobileText: "More About ISB"
    },
    {
      image: Krushai,
      text: (
        <>
          <div className='mb-2'><span className="font-bold  2xl:text-3xl ">KrushAI - Empowering Farmers:</span></div>
          <span className=" font-normal  text-[13px] sm:text-xs lg:text-base  md:text-xs xl:text-lg  text-xs-370  2xl:text-2xl">
            Our agricultural assistant, KrushAI, provides farmers with real-time information and guidance in their native language on government schemes, crop cycles, and best practices. This multilingual application is designed to empower farmers with the knowledge they need to optimize their yields and improve their livelihoods.
          </span>
        </>
      ),
      mobileText: "More About KrushAI"
    },
    {
      image: Samagra,
      text: (
        <>
          <div className='mb-2'><span className="font-bold 2xl:text-3xl ">A Multilingual Conversational Assistant:</span></div>
          <span className="font-normal text-base sm:text-xs lg:text-base  xl:text-lg md:text-xs 2xl:text-2xl">
          Our LLM-based multilingual assistant is meticulously trained on custom datasets, allowing us to provide users with accurate and insightful answers to their queries. This intelligent assistant engages in natural, conversational exchanges, ensuring a seamless experience that simplifies complex information and enhances user understanding.
          </span>
        </>
      ),
      mobileText: "More About Samagra"
    }
  ];

  const HoverCard = ({ image, text, mobileText }) => {
    const [isMobileTextVisible, setIsMobileTextVisible] = useState(false);

    const handleMobileTextClick = () => {
      setIsMobileTextVisible(true);
      setTimeout(() => {
        setIsMobileTextVisible(false);
      }, 3000);
    };

    return (
      <div className="relative overflow-hidden rounded-lg shadow-xl group" >
        <img
          src={image}
          alt="Card Image"
          className="object-cover transition-transform duration-300 ease-in-out w-full h-full "

        />
        {/* Desktop text - shown on hover for larger screens */}
        <div
          className={`absolute  inset-0 flex items-top justify-center items-center bg-white text-black
            opacity-0 md:group-hover:opacity-100 transition-all duration-300 ease-in-out md:px-8 lg:py-6 py-5 px-10  ${isMobileTextVisible ? 'md:opacity-0' : ''}`}
        >
          <p className="text-start font-bold text-lg leading-none">{text}</p>
        </div>
        {/* Mobile overlay text - shown when clicked on mobile */}
        <div
          className="absolute bottom-0 inset-x-0 flex items-center justify-center text-white underline p-4 md:hidden cursor-pointer"
          onClick={handleMobileTextClick}
        >
          <p className="text-lg font-bold">{mobileText}</p>
        </div>
        {/* Full text for mobile, toggled by click */}
        {isMobileTextVisible && (
          <div className="absolute inset-0 flex  bg-white text-black p-2 md:hidden z-20">
            <p className="text-start font-bold  md:text-large ">{text}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full mx-auto md:pt-[50px]">
      {/* H2 Section */}
      <h2 className=" pt-[30px] pb-[30px] pl-[40px]  pr-[40px] md:pl-[150px] md:pr-[150px] text-Outfit text-[20px] md:text-[38px] font-semibold lg:leading-[60px] leading-[25.2px] md:leading-[60px] text-[#0F0F0F] lg:text-5xl text-center">
      We leverage and optimise LLM models
      <p>to build solutions for</p>
      </h2>

      {/* Card Grid Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[20px] px-[10px] md:mt-[65px] md:px-[20px] mt-[20px] md:mt-[0px]">
        {cardDataArray.map((card, index) => (
          <HoverCard key={index} image={card.image} text={card.text} mobileText={card.mobileText} />
        ))}
      </div>
      <style jsx>{`
        @media (max-width: 370px) {
          .text-xs-370 {
            font-size: 12px !important;
          }
        }
          
      `}</style>
    </div>
  );
};

export default ExpertiseSection;


